import React from 'react';
import { useNavigate } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import LoadingGif from 'components/UI/LoadingGif';
import { Fight } from 'services/fights/types';
import links from 'utils/links';
import LastFightsList from './LastFightsList';

export interface FightInfosProps {
  fight?: Fight;
}

const formatOdds = odds => {
  if (odds === null || odds === undefined) return odds;
  if (odds > 0) return `+${odds}`;
  return odds;
};

const FightInfo = ({ fight }: FightInfosProps) => {
  const navigate = useNavigate();

  if (!fight) {
    return <LoadingGif />;
  }
  return (
    <Table
      responsive
      style={{
        tableLayout: 'fixed',
      }}
    >
      <thead>
        <tr>
          <th
            aria-label="Header"
            style={{
              width: 120,
            }}
          />
          <th
            onClick={() => {
              navigate(links.fighter(fight.red.id))
            }}
            style={{
              backgroundColor: '#CE0000',
              color: '#FFFFFF',
              cursor: 'pointer',
            }}
          >
            { fight.red.name }
          </th>
          <th
            aria-label="Header"
            style={{
              width: 15,
            }}
          />
          <th
            onClick={() => {
              navigate(links.fighter(fight.blue.id))
            }}
            style={{
              backgroundColor: '#0000CE',
              color: '#FFFFFF',
              cursor: 'pointer',
            }}
          >
            { fight.blue.name }
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Age</td>
          <td>{ Math.floor(fight.red.age) }</td>
          <td></td>
          <td>{ Math.floor(fight.blue.age) }</td>
        </tr>
        <tr>
          <td>Height</td>
          <td>{ fight.red.height }</td>
          <td></td>
          <td>{ fight.blue.height }</td>
        </tr>
        <tr>
          <td>Weight</td>
          <td>{ fight.red.weight } lbs</td>
          <td></td>
          <td>{ fight.blue.weight } lbs</td>
        </tr>
        <tr>
          <td>Reach</td>
          <td>{ fight.red.reach }</td>
          <td></td>
          <td>{ fight.blue.reach }</td>
        </tr>
        <tr>
          <td>Stance</td>
          <td>{ fight.red.stance }</td>
          <td></td>
          <td>{ fight.blue.stance }</td>
        </tr>
        <tr style={{ borderBottom: '5px solid #8D8D8D' }}>
          <td>Odds</td>
          <td>{ formatOdds(fight.red.odds) }</td>
          <td></td>
          <td>{ formatOdds(fight.blue.odds) }</td>
        </tr>
        <tr>
          <td>Pro Record</td>
          <td>{ fight.red.beforeAll && `${fight.red.beforeAll.W} - ${fight.red.beforeAll.L} - ${fight.red.beforeAll.D} (${fight.red.beforeAll.NC} NC)` }</td>
          <td></td>
          <td>{ fight.blue.beforeAll && `${fight.blue.beforeAll.W} - ${fight.blue.beforeAll.L} - ${fight.blue.beforeAll.D} (${fight.blue.beforeAll.NC} NC)` }</td>
        </tr>
        <tr>
          <td>Past fights</td>
          <td style={{ padding: 0 }}>
            <LastFightsList
              fighterId={fight.red.id}
              name={fight.red.name}
            />
          </td>
          <td></td>
          <td style={{ padding: 0 }}>
            <LastFightsList
              fighterId={fight.blue.id}
              name={fight.blue.name}
            />
          </td>
        </tr>
      </tbody>
    </Table>
  );
};

export default FightInfo;
