import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateTime } from 'luxon';
import { useDeleteRevisionMutation, useGetRevisionsQuery } from 'services/revisions/api';

const RevisionsList = ({
  onRevisionRestore,
  postId,
}) => {
  const { data: revisions } = useGetRevisionsQuery(postId);

  const [deleteRevision] = useDeleteRevisionMutation()

  return (
    <div
      style={{
        margin: '10px 0',
        textAlign: 'left',
      }}
    >
      <div
        style={{
          width: '100%',
        }}
      >
        {
          revisions?.sort((a, b) => (a.modifiedAt < b.modifiedAt ? 1 : -1))
            .map(revision => (
              <div
                key={revision.id}
                style={{
                  backgroundColor: '#f0f0f0',
                  borderRadius: 6,
                  padding: 10,
                  margin: '20px 0',
                }}
              >
                <div>
                  <FontAwesomeIcon
                    icon="times"
                    onClick={() => deleteRevision({
                      id: revision.id,
                      postId,
                    })}
                    size="lg"
                    style={{
                      color: 'red',
                      cursor: 'pointer',
                      position: 'absolute',
                      float: 'right',
                      right: 40,
                    }}
                  />
                  <FontAwesomeIcon
                    icon="arrow-rotate-left"
                    onClick={() => onRevisionRestore(revision)}
                    size="lg"
                    style={{
                      cursor: 'pointer',
                      position: 'absolute',
                      float: 'right',
                      right: 60,
                    }}
                  />
                  <table width="100%">
                    <tr>
                      <td colSpan={2}>
                        <b>{ DateTime.fromISO(revision.modifiedAt).toRelative() }</b>
                      </td>
                    </tr>
                    <tr color="#999999">
                      <td width="50%">
                        <FontAwesomeIcon
                          icon="font"
                        />
                        &nbsp;
                        { revision.nbWords }&nbsp;words
                      </td>
                      <td width="50%">
                        <FontAwesomeIcon
                          icon="clock"
                        />
                        &nbsp;
                        { Math.ceil(revision.readingTime) }&nbsp;minutes
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            ))
        }
      </div>
    </div>
  );
};

export default RevisionsList;
