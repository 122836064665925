import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Button from 'react-bootstrap/Button';
import ClickOutside from 'react-click-outside';
import { Helmet } from 'react-helmet';
import SlidingPane from 'react-sliding-pane';
import { DateTime } from 'luxon';
import ContestsList from 'components/admin/ContestsList';
import ContestForm from 'components/admin/ContestForm';
import ContestResultsForm from 'components/admin/ContestResultsForm';
import Sidebar from 'components/UI/Sidebar';
import { useGetContestsQuery } from 'services/contests/api';
import { useAuth } from 'services/login/hooks';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-sliding-pane/dist/react-sliding-pane.css';

const ContestsPage = () => {
  const auth = useAuth();
  const { data: contests } = useGetContestsQuery();
  const [editedContestId, setEditedContestId] = useState<string | null>(null);
  const [editedContestIdForResults, setEditedContestIdForResults] = useState<string | null>(null);
  const pastContests = contests?.filter(c => DateTime.fromISO(c.endsAt) < DateTime.now());
  const futureContests = contests?.filter(c => DateTime.fromISO(c.endsAt) >= DateTime.now());

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <Sidebar />
      <Container
        fluid
        style={{
          paddingTop: 110,
        }}
      >
        <Row>
          <Col>
            <div style={{ marginLeft: 100 }}>
              {
                auth.user.permissions?.includes('contests.create') && (
                  <Button
                    onClick={() => setEditedContestId('NEW')}
                    style={{
                      float: 'right',
                    }}
                    variant="primary"
                  >
                    Create
                  </Button>
                )
              }
              <h3>Contests</h3>
              <Tabs defaultActiveKey="FUTURE">
                <Tab
                  eventKey="FUTURE"
                  title={`Upcoming (${futureContests?.length})`}
                >
                  <ContestsList
                    contests={futureContests}
                    onEditContest={contestId => setEditedContestId(contestId)}
                  />
                </Tab>
                <Tab
                  eventKey="PAST"
                  title={`Past (${pastContests?.length})`}
                >
                  <ContestsList
                    contests={pastContests}
                    onEditContestResults={contestId => setEditedContestIdForResults(contestId)}
                  />
                </Tab>
              </Tabs>
            </div>
            <SlidingPane
              className="contest_sliding-pane"
              isOpen={!!editedContestId}
              onRequestClose={() => setEditedContestId(null)}
              overlayClassName="slidingPane_overlay"
            >
              <ClickOutside
                onClickOutside={() => setEditedContestId(null)}
              >
                <ContestForm
                  contestId={editedContestId}
                  onClose={() => setEditedContestId(null)}
                />
              </ClickOutside>
            </SlidingPane>
            <SlidingPane
              className="contest_sliding-pane"
              isOpen={!!editedContestIdForResults}
              onRequestClose={() => setEditedContestId(null)}
              overlayClassName="slidingPane_overlay"
            >
              <ClickOutside
                onClickOutside={() => setEditedContestIdForResults(null)}
              >
                <ContestResultsForm
                  contestId={editedContestIdForResults}
                  onClose={() => setEditedContestIdForResults(null)}
                />
              </ClickOutside>
            </SlidingPane>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ContestsPage;
