import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Helmet } from 'react-helmet';
import Sidebar from 'components/UI/Sidebar';
import PredictionsList from 'components/predictions/PredictionsList';
import { useGetPredictionsQuery } from 'services/predictions/api';

const PredictionsPage = () => {
  const { data: predictions } = useGetPredictionsQuery()

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <Sidebar />
      <Container
        fluid
        style={{
          paddingTop: 110,
        }}
      >
        <Row>
          <Col>
            <div style={{ marginLeft: 100 }}>
              <h3>Predictions</h3>
              <PredictionsList
                predictions={predictions}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default PredictionsPage;
