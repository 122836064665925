import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { formatYYYYMMDD } from 'utils/date';
import envvars from 'utils/envvars';
import { Event } from './types';

export const apiEvents = createApi({
  reducerPath: 'eventsApi',
  tagTypes: ['Event'],
  baseQuery: fetchBaseQuery({
    baseUrl: (process.env.NODE_ENV === 'production')
      ? `${envvars.apiServer}/v1`
      : 'http://localhost:3000/v1/',
    credentials: 'include',
  }),
  endpoints: builder => ({
    getEvents: builder.query<Event[], any>({
      query: ({
        filter = { date: { $gte: formatYYYYMMDD() }},
        sort,
        limit,
      }) => {
        return {
          url: 'events',
          params: {
            filter: JSON.stringify(filter),
            sort: JSON.stringify(sort),
            limit,
          },
        };
      },
      transformResponse: (data: { events: Event[] }) => data.events,
      transformErrorResponse: (response: { status: string | number }) => response.status,
      providesTags: ['Event'],
    }),
    getEvent: builder.query<Event, string>({
      query: id => `events/${id}`,
      transformResponse: (data: { event: Event }) => data.event,
      transformErrorResponse: (response: { status: string | number }) => response.status,
      providesTags: ['Event'],
    }),
    addEvent: builder.mutation<Event, Partial<Event>>({
      query: body => ({
        url: `events`,
        method: 'POST',
        body,
      }),
      transformResponse: (data: { event: Event }) => data.event,
      transformErrorResponse: (response: { status: string | number }) => response.status,
      invalidatesTags: ['Event'],
    }),
    patchEvent: builder.mutation<Event, Partial<Event> & Pick<Event, 'id'>>({
      query: ({ id, ...body }) => ({
        url: `events/${id}`,
        method: 'PATCH',
        body,
      }),
      transformResponse: (data: { event: Event }) => data.event,
      transformErrorResponse: (response: { status: string | number }) => response.status,
      invalidatesTags: ['Event'],
    }),
    updateEvent: builder.mutation<Event, Partial<Event> & Pick<Event, 'id'>>({
      query: ({ id, ...body }) => ({
        url: `events/${id}`,
        method: 'PUT',
        body,
      }),
      transformResponse: (data: { event: Event }) => data.event,
      transformErrorResponse: (response: { status: string | number }) => response.status,
      invalidatesTags: ['Event'],
    }),
    deleteEvent: builder.mutation<string, string>({
      query: id => ({
        url: `events/${id}`,
        method: 'DELETE',
      }),
      transformResponse: (data: { eventId: string }) => data.eventId,
      transformErrorResponse: (response: { status: string | number }) => response.status,
      invalidatesTags: ['Event'],
    }),
  }),
});

export const {
  useGetEventsQuery,
  useLazyGetEventQuery,
  useAddEventMutation,
  usePatchEventMutation,
  useUpdateEventMutation,
  useDeleteEventMutation,
} = apiEvents;
