import React from 'react';
import { Link } from 'react-router-dom';
import { DateTime } from 'luxon';
import links from 'utils/links';

const PostPreview = ({ post }) => (
  <div
    style={{
      margin: '10px 0',
      textAlign: 'left',
    }}
  >
    <table>
      <tbody>
        <tr>
          <td>
            <img
              alt={post.title}
              src={post.mainImage}
              style={{
                height: 80,
                margin: '10px 10px 10px 0',
                objectFit: 'cover',
                width: 120,
              }}
            />
          </td>
          <td>
            <h4>
              <Link to={links.post(post.urlId)}>
                {post.title}
              </Link>
            </h4>
            <p style={{ color: '#a9a9a9' }}>
              <b>
                By&nbsp;
                <Link to={links.user(post.userName)}>
                  {post.userName}
                </Link>
                , published&nbsp;
                {DateTime.fromISO(post.publishedAt).toRelative()}
              </b>
            </p>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
);

export default PostPreview;
