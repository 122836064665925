import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import envvars from 'utils/envvars';
import { File } from './types';

export const apiFiles = createApi({
  reducerPath: 'filesApi',
  tagTypes: ['File'],
  baseQuery: fetchBaseQuery({
    baseUrl: (process.env.NODE_ENV === 'production')
      ? `${envvars.apiServer}/v1`
      : 'http://localhost:3000/v1/',
    credentials: 'include',
  }),
  endpoints: builder => ({
    getFiles: builder.query<File[], void>({
      query: () => `files`,
      transformResponse: (data: { files: any }) => data.files.Contents,
      transformErrorResponse: (response: { status: string | number }) => response.status,
      providesTags: ['File'],
    }),
  }),
});

export const {
  useGetFilesQuery,
} = apiFiles;
