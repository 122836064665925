import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import RBForm from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Helmet } from 'react-helmet';
import { Form, Field } from 'react-final-form';
import SlidingPane from 'react-sliding-pane';
import usePrevious from 'use-previous';
import PostText from 'components/blog/PostText';
import RevisionsList from 'components/blog/RevisionsList';
import AutosaveTextarea from 'components/UI/AutosaveTextarea/AutosaveTextarea';
import Dropzone from 'components/UI/Dropzone';
import Sidebar from 'components/UI/Sidebar';
import { useGetPostsQuery, useAddPostMutation, usePatchPostMutation, useUpdatePostMutation } from 'services/posts/api';
import 'react-sliding-pane/dist/react-sliding-pane.css';

const PostUpsertPage = () => {
  const { id } = useParams();
  const { data: posts } = useGetPostsQuery();
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [isRevisionsListOpen, setIsRevisionsListOpen] = useState(false);
  const post = posts?.find(p => p.id === id);
  const prevPost = usePrevious(post);
  const [text, setText] = useState(post?.text);
  const [textFr, setTextFr] = useState(post?.textFr);

  const [addPost] = useAddPostMutation();
  const [patchPost] = usePatchPostMutation();
  const [updatePost] = useUpdatePostMutation();

  useEffect(() => {
    if (post && !prevPost) {
      setText(post.text);
      setTextFr(post.textFr);
    }
  }, [post]);

  const handleRevisionRestore = revision => {
    setText(revision.text);
    setIsRevisionsListOpen(false);
  };

  const onSubmit = async values => {
    if (post) {
      updatePost({
        id: post.id,
        ...values,
      });
    } else {
      addPost(values);
    }
  };

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <Sidebar />
      <Container
        fluid
        style={{
          paddingTop: 110,
        }}
      >
        <Row>
          <Col>
            <div style={{ marginLeft: 100 }}>
              <h3>Create a post</h3>
              <Button
                onClick={() => setIsRevisionsListOpen(true)}
                style={{
                  float: 'right',
                  position: 'relative',
                  bottom: 40,
                  margin: 5,
                }}
                variant="primary"
              >
                Revisions
              </Button>
              <Button
                onClick={() => setIsPreviewOpen(true)}
                style={{
                  float: 'right',
                  position: 'relative',
                  bottom: 40,
                  margin: 5,
                }}
                variant="primary"
              >
                Preview
              </Button>
              <br />
              <Form
                onSubmit={onSubmit}
                initialValues={post}
                render={({ handleSubmit }) => (
                  <form
                    encType="multipart/form-data"
                    onSubmit={handleSubmit}
                  >
                    <RBForm.Group>
                      <RBForm.Label>Title</RBForm.Label>
                      <Field
                        className="form-control"
                        component="input"
                        name="title"
                      />
                    </RBForm.Group>
                    <RBForm.Group>
                      <RBForm.Label>Main image</RBForm.Label>
                      <Field
                        className="form-control"
                        component="input"
                        name="mainImage"
                      />
                    </RBForm.Group>
                    <RBForm.Group>
                      <RBForm.Label>Logo</RBForm.Label>
                      <Field
                        className="form-control"
                        name="logo"
                        type="file"
                      >
                        {
                          ({ input }) => (
                            <Dropzone
                              {...input}
                              onFileUpload={() => undefined}
                            />
                          )
                        }
                      </Field>
                    </RBForm.Group>
                    <RBForm.Group>
                      <RBForm.Label>Text</RBForm.Label>
                      <Field
                        className="form-control"
                        name="text"
                        rows={20}
                        type="text"
                      >
                        {
                          ({ input }) => (
                            <AutosaveTextarea
                              defaultValue={text}
                              onSave={async t => {
                                input.onChange(t);
                                setText(t);
                                if (post) {
                                  patchPost({
                                    id: post.id,
                                    text: t?.replace(/<br\s*\/?>/mg, '\n'),
                                  });
                                }
                              }}
                              showSavedCheck
                            />
                          )
                        }
                      </Field>
                    </RBForm.Group>
                    <RBForm.Group>
                      <RBForm.Label>Text (Fr)</RBForm.Label>
                      <Field
                        className="form-control"
                        name="textFr"
                        rows={20}
                        type="text"
                      >
                        {
                          ({ input }) => (
                            <AutosaveTextarea
                              defaultValue={textFr}
                              onSave={async t => {
                                input.onChange(t);
                                setTextFr(t);
                                if (post) {
                                  patchPost({
                                    id: post.id,
                                    textFr: t?.replace(/<br\s*\/?>/mg, '\n'),
                                  });
                                }
                              }}
                              showSavedCheck
                            />
                          )
                        }
                      </Field>
                    </RBForm.Group>
                    <RBForm.Group>
                      <RBForm.Label>Published</RBForm.Label>
                      <Field
                        component="input"
                        className="form-control"
                        name="isPublished"
                        type="checkbox"
                      />
                    </RBForm.Group>
                    <br />
                    <div>
                      {
                        post
                          ? <Button type="submit" variant='success'>Save</Button>
                          : <Button type="submit" variant='success'>Create</Button>
                      }
                    </div>
                  </form>
                )}
              />
              <SlidingPane
                isOpen={isPreviewOpen}
                onRequestClose={() => setIsPreviewOpen(false)}
                overlayClassName="slidingPane_overlay"
              >
                <Container
                  fluid
                  style={{
                    paddingTop: 30,
                  }}
                >
                  <Row>
                    <Col>
                      <PostText
                        text={text}
                      />
                    </Col>
                  </Row>
                </Container>
              </SlidingPane>
              <SlidingPane
                isOpen={isRevisionsListOpen}
                onRequestClose={() => setIsRevisionsListOpen(false)}
                width="400px"
              >
                <RevisionsList
                  onRevisionRestore={handleRevisionRestore}
                  postId={post?.id}
                />
              </SlidingPane>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default PostUpsertPage;
