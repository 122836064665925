import React from 'react';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Button from 'react-bootstrap/Button';
import { Helmet } from 'react-helmet';
import PostsList from 'components/admin/PostsList';
import Sidebar from 'components/UI/Sidebar';
import { useAuth } from 'services/login/hooks';
import { useGetPostsQuery, usePatchPostMutation, useDeletePostMutation } from 'services/posts/api';
import links from 'utils/links';

const PostsPage = () => {
  const auth = useAuth();
  const { data: posts } = useGetPostsQuery();
  const myPosts = posts?.filter(post => post.userId === auth.user.id);
  const myDrafts = myPosts?.filter(post => !post.isPublished && !post.isDeleted);
  const myPublishedPosts = myPosts?.filter(post => post.isPublished && !post.isDeleted);
  const deletedPosts = myPosts?.filter(post => post.isDeleted);

  const [patchPost] = usePatchPostMutation();
  const [deletePost] = useDeletePostMutation();

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <Sidebar />
      <Container
        fluid
        style={{
          paddingTop: 110,
        }}
      >
        <Row>
          <Col>
            <div style={{ marginLeft: 100 }}>
              <h3>Posts</h3>
              {
                auth.user.permissions?.includes('posts.create') && (
                  <Link to={links.adminPostUpsert()}>
                    <Button
                      style={{
                        float: 'right',
                        position: 'relative',
                        bottom: 40,
                      }}
                      variant="primary"
                    >
                      Create
                    </Button>
                  </Link>
                )
              }
              {
                auth.user.permissions?.includes('files.list') && (
                  <Link to={links.adminFiles()}>
                    <Button
                      style={{
                        float: 'right',
                        marginRight: 5,
                        position: 'relative',
                        bottom: 40,
                      }}
                      variant="outline-primary"
                    >
                      Files
                    </Button>
                  </Link>
                )
              }
              <Tabs defaultActiveKey="DRAFTS">
                <Tab
                  eventKey="DRAFTS"
                  title={`Drafts (${myDrafts?.length})`}
                >
                  <PostsList
                    onDeletePost={post => patchPost({
                      id: post.id,
                      isDeleted: true,
                    })}
                    posts={myDrafts}
                  />
                </Tab>
                <Tab
                  eventKey="PUBLISHED"
                  title={`Published (${myPublishedPosts?.length})`}
                >
                  <PostsList
                    onDeletePost={post => patchPost({
                      id: post.id,
                      isDeleted: true,
                    })}
                    posts={myPublishedPosts}
                  />
                </Tab>
                <Tab
                  eventKey="DELETED"
                  title={`Deleted (${deletedPosts?.length})`}
                >
                  <PostsList
                    onDeletePost={post => deletePost(post.id)}
                    posts={deletedPosts}
                  />
                </Tab>
              </Tabs>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default PostsPage;
