import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import envvars from 'utils/envvars';
import { User } from './types';

export const apiUsers = createApi({
  reducerPath: 'usersApi',
  tagTypes: ['User'],
  baseQuery: fetchBaseQuery({
    baseUrl: (process.env.NODE_ENV === 'production')
      ? `${envvars.apiServer}/v1`
      : 'http://localhost:3000/v1/',
    credentials: 'include',
  }),
  endpoints: builder => ({
    getUsers: builder.query<User[], void>({
      query: () => 'users',
      transformResponse: (data: { users: User[] }) => data.users,
      transformErrorResponse: (response: { status: string | number }) => response.status,
      providesTags: ['User'],
    }),
    getUser: builder.query<User, string>({
      query: id => `users/${id}`,
      transformResponse: (data: { user: User }) => data.user,
      transformErrorResponse: (response: { status: string | number }) => response.status,
      providesTags: ['User'],
    }),
    addUser: builder.mutation<User, Partial<User>>({
      query: body => ({
        url: `users`,
        method: 'POST',
        body,
      }),
      transformResponse: (data: { user: User }) => data.user,
      transformErrorResponse: (response: { status: string | number }) => response.status,
      invalidatesTags: ['User'],
    }),
    patchUser: builder.mutation<User, Partial<User> & Pick<User, 'id'>>({
      query: ({ id, ...body }) => ({
        url: `users/${id}`,
        method: 'PATCH',
        body,
      }),
      transformResponse: (data: { user: User }) => data.user,
      transformErrorResponse: (response: { status: string | number }) => response.status,
      invalidatesTags: ['User'],
    }),
    updateUser: builder.mutation<User, Partial<User> & Pick<User, 'id'>>({
      query: ({ id, ...body }) => ({
        url: `users/${id}`,
        method: 'PUT',
        body,
      }),
      transformResponse: (data: { user: User }) => data.user,
      transformErrorResponse: (response: { status: string | number }) => response.status,
      invalidatesTags: ['User'],
    }),
    deleteUser: builder.mutation<string, string>({
      query: id => ({
        url: `users/${id}`,
        method: 'DELETE',
      }),
      transformResponse: (data: { userId: string }) => data.userId,
      transformErrorResponse: (response: { status: string | number }) => response.status,
      invalidatesTags: ['User'],
    }),
  }),
});

export const {
  useGetUsersQuery,
  useGetUserQuery,
  useAddUserMutation,
  usePatchUserMutation,
  useUpdateUserMutation,
  useDeleteUserMutation,
} = apiUsers;
