import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga4';
import PostPreview from 'components/blog/PostPreview';
import FacebookPagePlugin from 'components/UI/FacebookPagePlugin';
import { useGetPostsQuery } from 'services/posts/api';

const BlogPage = () => {
  const location = useLocation();
  const { data: posts } = useGetPostsQuery();

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: location.pathname,
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>LosersWinBig - Blog</title>
      </Helmet>
      <Container
        fluid
        style={{
          paddingTop: 110,
        }}
      >
        <Row>
          <Col md lg={9}>
            <Breadcrumb>
              <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
              <Breadcrumb.Item active>Blog</Breadcrumb.Item>
            </Breadcrumb>
            <h3>Blog</h3>
            {
              posts?.filter(post => post.isPublished)
                .map(post => (
                  <PostPreview
                    key={post.id}
                    post={post}
                  />
                ))
            }
          </Col>
          <Col lg={3}>
            <h3>Follow loserswinbig</h3>
            <FacebookPagePlugin />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default BlogPage;
