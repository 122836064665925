import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import envvars from 'utils/envvars';
import { Contest } from './types';

export const apiContests = createApi({
  reducerPath: 'contestsApi',
  tagTypes: ['Contest'],
  baseQuery: fetchBaseQuery({
    baseUrl: (process.env.NODE_ENV === 'production')
      ? `${envvars.apiServer}/v1`
      : 'http://localhost:3000/v1/',
    credentials: 'include',
  }),
  endpoints: builder => ({
    getContests: builder.query<Contest[], void>({
      query: () => 'contests',
      transformResponse: (data: { contests: Contest[] }) => data.contests,
      transformErrorResponse: (response: { status: string | number }) => response.status,
      providesTags: ['Contest'],
    }),
    getContest: builder.query<Contest, string>({
      query: id => `contests/${id}`,
      transformResponse: (data: { contest: Contest }) => data.contest,
      transformErrorResponse: (response: { status: string | number }) => response.status,
      providesTags: ['Contest'],
    }),
    addContest: builder.mutation<Contest, Partial<Contest>>({
      query: body => ({
        url: `contests`,
        method: 'POST',
        body,
      }),
      transformResponse: (data: { contest: Contest }) => data.contest,
      transformErrorResponse: (response: { status: string | number }) => response.status,
      invalidatesTags: ['Contest'],
    }),
    patchContest: builder.mutation<Contest, Partial<Contest> & Pick<Contest, 'id'>>({
      query: ({ id, ...body }) => ({
        url: `contests/${id}`,
        method: 'PATCH',
        body,
      }),
      transformResponse: (data: { contest: Contest }) => data.contest,
      transformErrorResponse: (response: { status: string | number }) => response.status,
      invalidatesTags: ['Contest'],
    }),
    updateContest: builder.mutation<Contest, Partial<Contest> & Pick<Contest, 'id'>>({
      query: ({ id, ...body }) => ({
        url: `contests/${id}`,
        method: 'PUT',
        body,
      }),
      transformResponse: (data: { contest: Contest }) => data.contest,
      transformErrorResponse: (response: { status: string | number }) => response.status,
      invalidatesTags: ['Contest'],
    }),
    deleteContest: builder.mutation<string, string>({
      query: id => ({
        url: `contests/${id}`,
        method: 'DELETE',
      }),
      transformResponse: (data: { contestId: string }) => data.contestId,
      transformErrorResponse: (response: { status: string | number }) => response.status,
      invalidatesTags: ['Contest'],
    }),
  }),
});

export const {
  useGetContestsQuery,
  useGetContestQuery,
  useAddContestMutation,
  usePatchContestMutation,
  useUpdateContestMutation,
  useDeleteContestMutation,
} = apiContests;
