import React, { useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ReactGA from 'react-ga4';
import { Helmet } from 'react-helmet';
import PostPreview from 'components/blog/PostPreview';
import UpcomingEventsList from 'components/events/UpcomingEventsList';
import LatestNewsList from 'components/news/LatestNewsList';
import FacebookPagePlugin from 'components/UI/FacebookPagePlugin';
import { useGetPostsQuery } from 'services/posts/api';
import UFCLogo from '../../images/UFClogo.svg';
import iconFacebook from '../../images/icon_facebook.png';
import iconInstagram from '../../images/icon_instagram.png';
import './style.scss';

const HomePage = () => {
  const { data: posts } = useGetPostsQuery();

  useEffect(() => {
    ReactGA.send('pageview');
  }, []);

  return (
    <>
      <Helmet>
        <title>LosersWinBig - Home</title>
      </Helmet>
      <Container
        fluid
        style={{
          paddingTop: 110,
        }}
      >
        <Row>
          <Col lg={6}>
            <h3>Upcoming Events</h3>
            <h4>MMA <img src={UFCLogo} alt='' height={15} /></h4>
            <UpcomingEventsList />
            <h3>Latest Updates</h3>
            <LatestNewsList />
          </Col>
          <Col lg={6}>
            <h3>Latest Posts</h3>
            {
              posts?.filter(post => post.isPublished)
                .sort((a, b) => (a.publishedAt < b.publishedAt ? 1 : -1))
                .slice(0, 10)
                .map(post => (
                  <div
                    key={post.id}
                    style={{
                      textAlign: 'left',
                    }}
                  >
                    <PostPreview
                      post={post}
                    />
                  </div>
                ))
            }
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <h3>Follow loserswinbig</h3>
            <div style={{ fontSize: 20, margin: '15px 0' }}>
              <a href="https://www.facebook.com/loserswinbig">
                <img
                  alt="Facebook"
                  src={iconFacebook}
                  style={{ marginRight: 5 }}
                  width="30"
                />
                @loserswinbig
              </a>
            </div>
            {
              /*
              <div style={{ fontSize: 20, margin: '15px 0' }}>
                <a href="https://x.com/loserswinbig" style={{ fontSize: 20, margin: '20px 0' }}>
                  <img src="/icon_x.png" width="30" style={{ marginRight: 5 }} />
                  @loserswinbig
                </a>
              </div>
              */
            }
            <div style={{ fontSize: 20, margin: '15px 0' }}>
              <a href="https://instagram.com/loserswinbig" style={{ fontSize: 20, margin: '20px 0' }}>
                <img
                  alt="Instagram"
                  src={iconInstagram}
                  style={{ marginRight: 5 }}
                  width="30"
                />
                @loserswinbig
              </a>
            </div>
            <FacebookPagePlugin />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default HomePage;
