import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import RBForm from 'react-bootstrap/Form';
import { Form, Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import RadioButtons from 'components/UI/RadioButtons';
import { Contest } from 'services/contests/types';
import { useGetFightsQuery } from 'services/fights/api';
import { useAddPredictionMutation, useUpdatePredictionMutation } from 'services/predictions/api';
import { Prediction } from 'services/predictions/types';
import { showError } from 'utils/notifications';

interface PredictionFormProps {
  contest: Contest;
  onClose: () => void;
  prediction?: Prediction;
}

const PredictionForm = ({
  contest,
  onClose,
  prediction,
}: PredictionFormProps) => {
  const { data: fights } = useGetFightsQuery({
    filter: { eventId: contest.eventId },
  });
  const [winnerIds, setWinnerIds] = useState(
    new Map(
      prediction
      ? prediction.forecasts.map(f => ([f.fightId, f.winnerId]))
      : [],
    ),
  );
  const [methods, setMethods] = useState(
    new Map(
      prediction
      ? prediction.forecasts.map(f => ([f.fightId, f.method]))
      : [],
    ),
  );

  const [addPrediction] = useAddPredictionMutation();
  const [updatePrediction] = useUpdatePredictionMutation();

  const onSubmit = async values => {
    if (prediction && prediction.id) {
      updatePrediction({
        id: contest.id,
        ...values,
      })
        .then(() => onClose())
        .catch(error => showError(error.message));
    } else {
      addPrediction(values)
        .then(() => onClose())
        .catch(error => showError(error.message));
    }
  };

  const predictionWithInitialForecasts = {
    ...prediction,
    forecasts: contest.fights
      .map(fight => {
        const forecast = prediction?.forecasts?.find(f => f.fightId === fight.id);
        return {
          fightId: fight.id,
          winnerId: forecast?.winnerId,
          method: forecast?.method,
          round: forecast?.round,
        };
      }),
  };

  return contest ? (
    <div>
      <h3
        style={{
          color: '#d65d0c',
        }}
      >
        {contest.title}
      </h3>
      <Form
        keepDirtyOnReinitialize
        initialValues={predictionWithInitialForecasts}
        mutators={{ ...arrayMutators }}
        onSubmit={onSubmit}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <RBForm.Group>
              <Field
                className="form-control"
                component="input"
                defaultValue={contest.id}
                name="contestId"
              >
                {
                  ({ input }) => (
                    <input
                      type="hidden"
                      name="contestId"
                      value={input.value}
                    />
                  )
                }
              </Field>
            </RBForm.Group>
            <RBForm.Group>
              <Field
                className="form-control"
                component="input"
                defaultValue={contest.eventId}
                name="eventId"
              >
                {
                  ({ input }) => (
                    <input
                      type="hidden"
                      name="eventId"
                      value={input.value}
                    />
                  )
                }
              </Field>
            </RBForm.Group>
            <RBForm.Group>
              <FieldArray name="forecasts">
                {({ fields }) => (
                  <div>
                    {
                      fields
                        .map((name, index) => {
                          const { fightId } = predictionWithInitialForecasts.forecasts[index];
                          const fight = fights?.find(f => f.id === fightId);
                          const maxNbRounds = contest.fights.find(f => f.id === fightId)?.maxNbRounds;
                          const forecast = prediction?.forecasts.find(f => f.fightId === fightId);
                          return fight && (
                            <div key={name}>
                              <h4>
                                {fight.red.name} vs. {fight.blue.name}
                              </h4>
                              <Field
                                className="form-control"
                                name={`${name}.fightId`}
                              >
                                {
                                  ({ input }) => (
                                    <input
                                      type="hidden"
                                      name="fightId"
                                      value={input.value}
                                    />
                                  )
                                }
                              </Field>
                              <Field
                                className="form-control"
                                component="input"
                                name={`${name}.winnerId`}
                              >
                                {
                                  ({ input }) => (
                                    <>
                                      <input
                                        type="hidden"
                                        name="winnerId"
                                        value={input.value}
                                      />
                                      <RadioButtons
                                        defaultSelected={forecast?.winnerId}
                                        onOptionClick={id => {
                                          input.onChange(id);
                                          setWinnerIds(new Map<string, any>([
                                            ...winnerIds.entries(),
                                            [fightId, id],
                                          ]));
                                        }}
                                        options={[
                                          { id: fight.red.id, name: fight.red.name },
                                          { id: 'Draw', name: 'Draw' },
                                          { id: fight.blue.id, name: fight.blue.name },
                                        ]}
                                      />
                                    </>
                                  )
                                }
                              </Field>
                              {
                                winnerIds.has(fight.id)
                                && winnerIds.get(fight.id) !== 'Draw'
                                && (
                                  <Field
                                    className="form-control"
                                    component="input"
                                    name={`${name}.method`}
                                  >
                                    {
                                      ({ input }) => (
                                        <>
                                          <input
                                            type="hidden"
                                            name="method"
                                            value={input.value}
                                          />
                                          <RadioButtons
                                            defaultSelected={forecast?.method}
                                            onOptionClick={method => {
                                              input.onChange(method);
                                              setMethods(new Map<string, any>([
                                                ...methods.entries(),
                                                [fightId, method],
                                              ]));
                                            }}
                                            options={[
                                              { id: 'Decision', name: 'Decision' },
                                              { id: 'KO/TKO', name: 'KO/TKO' },
                                              { id: 'Submission', name: 'Submission' },
                                            ]}
                                          />
                                        </>
                                      )
                                    }
                                  </Field>
                                )
                              }
                              {
                                methods.has(fight.id)
                                && methods.get(fight.id) !== 'Decision'
                                && (
                                  <Field
                                    className="form-control"
                                    component="input"
                                    name={`${name}.round`}
                                  >
                                    {
                                      ({ input }) => (
                                        <>
                                          <input
                                            type="hidden"
                                            name="round"
                                            value={input.value}
                                          />
                                          <RadioButtons
                                            defaultSelected={forecast?.round}
                                            onOptionClick={round => {
                                              input.onChange(round);
                                            }}
                                            options={
                                              [...Array(maxNbRounds).keys()].map(n => ({
                                                id: n + 1,
                                                name: (n + 1).toString(),
                                              }))
                                            }
                                          />
                                        </>
                                      )
                                    }
                                  </Field>
                                )
                              }
                            </div>
                          );
                        })
                    }
                  </div>
                )}
              </FieldArray>
            </RBForm.Group>
            <div
              style={{
                float: 'right',
                paddingBottom: 15,
                position: 'absolute',
                right: 30,
              }}
            >
              <Button variant="outline-secondary" onClick={() => onClose()}>Cancel</Button>
              {
                contest
                  ? <Button type="submit" variant='success'>Save</Button>
                  : <Button type="submit" variant='success'>Create</Button>
              }
            </div>
          </form>
        )}
      />
    </div>
  ) : <div />;
};

export default PredictionForm;
