import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import RBForm from 'react-bootstrap/Form';
import { Form, Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import RadioButtons from 'components/UI/RadioButtons';
import { useAddContestMutation, useGetContestsQuery, useUpdateContestMutation } from 'services/contests/api';
import { useGetFightsQuery } from 'services/fights/api';

const ContestResultsForm = ({
  contestId,
  onClose,
}) => {
  const { data: contests } = useGetContestsQuery();
  const contest = contests?.find(c => c.id === contestId);
  const { data: fights } = useGetFightsQuery({
    filter: contest?.eventId
      ? { eventId: contest?.eventId }
      : undefined,
  });
  const [winnerIds, setWinnerIds] = useState<any>(
    new Map(
      contest
      ? contest.results?.map(f => ([f.fightId, f.winnerId]))
      : [],
    ),
  );
  const [methods] = useState(
    new Map(
      contest
      ? contest.results?.map(f => ([f.fightId, f.method]))
      : [],
    ),
  );

  const [addContest] = useAddContestMutation();
  const [updateContest] = useUpdateContestMutation();

  const onSubmit = async values => {
    values.betValue = parseInt(values.betValue, 10);
    if (contest && contest.id) {
      updateContest({
        id: contest.id,
        ...values,
      })
        .then(() => onClose());
    } else {
      addContest(values)
        .then(() => onClose());
    }
  };

  const predictionWithInitialForecasts = {
    results: contest?.fights
      .map(fight => {
        const forecast = contest?.results?.find(f => f.fightId === fight.id);
        return {
          fightId: fight.id,
          winnerId: forecast?.winnerId,
          method: forecast?.method,
          round: forecast?.round,
        };
      }),
  };

  return contest ? (
    <Form
      initialValues={predictionWithInitialForecasts}
      mutators={{ ...arrayMutators }}
      onSubmit={onSubmit}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <RBForm.Group>
            <FieldArray name="results">
              {
                ({ fields }) => (
                  <div>
                    {
                      fields
                        .map((name, index) => {
                          const { fightId } = (predictionWithInitialForecasts.results || [])[index];
                          const fight = fights?.find(f => f.id === fightId);
                          const maxNbRounds = contest.fights.find(f => f.id === fightId)?.maxNbRounds;
                          const forecast = contest?.results?.find(f => f.fightId === fightId);
                          return fight && (
                            <div key={name}>
                              <h4>
                                {fight.red.name} vs. {fight.blue.name}
                              </h4>
                              <Field
                                className="form-control"
                                name={`${name}.fightId`}
                              >
                                {
                                  ({ input }) => (
                                    <input
                                      type="hidden"
                                      name="fightId"
                                      value={input.value}
                                    />
                                  )
                                }
                              </Field>
                              <Field
                                className="form-control"
                                component="input"
                                name={`${name}.winnerId`}
                              >
                                {
                                  ({ input }) => (
                                    <>
                                      <input
                                        type="hidden"
                                        name="winnerId"
                                        value={input.value}
                                      />
                                      <RadioButtons
                                        defaultSelected={forecast?.winnerId}
                                        onOptionClick={id => {
                                          input.onChange(id);
                                          setWinnerIds(new Map<string, any>([
                                            ...winnerIds.entries(),
                                            [fightId, id],
                                          ]));
                                        }}
                                        options={[
                                          { id: fight.red.id, name: fight.red.name },
                                          { id: 'Draw', name: 'Draw' },
                                          { id: fight.blue.id, name: fight.blue.name },
                                        ]}
                                      />
                                    </>
                                  )
                                }
                              </Field>
                              {
                                winnerIds.has(fight.id)
                                && winnerIds.get(fight.id) !== 'Draw'
                                && (
                                  <Field
                                    className="form-control"
                                    component="input"
                                    name={`${name}.method`}
                                  >
                                    {
                                      ({ input }) => (
                                        <>
                                          <input
                                            type="hidden"
                                            name="method"
                                            value={input.value}
                                          />
                                          <RadioButtons
                                            defaultSelected={forecast?.method}
                                            onOptionClick={method => {
                                              input.onChange(method);
                                              // TODO
                                              /*setMethods(new Map<string, any>([
                                                ...methods.entries(),
                                                [fightId, method],
                                              ]));
                                              */
                                            }}
                                            options={[
                                              { id: 'Decision', name: 'Decision' },
                                              { id: 'KO/TKO', name: 'KO/TKO' },
                                              { id: 'Submission', name: 'Submission' },
                                            ]}
                                          />
                                        </>
                                      )
                                    }
                                  </Field>
                                )
                              }
                              {
                                methods.has(fight.id)
                                && methods.get(fight.id) !== 'Decision'
                                && (
                                  <Field
                                    className="form-control"
                                    component="input"
                                    name={`${name}.round`}
                                  >
                                    {
                                      ({ input }) => (
                                        <>
                                          <input
                                            type="hidden"
                                            name="round"
                                            value={input.value}
                                          />
                                          <RadioButtons
                                            defaultSelected={forecast?.round}
                                            onOptionClick={round => {
                                              input.onChange(round);
                                            }}
                                            options={
                                              [...Array(maxNbRounds).keys()].map(n => ({
                                                id: n + 1,
                                                name: (n + 1).toString(),
                                              }))
                                            }
                                          />
                                        </>
                                      )
                                    }
                                  </Field>
                                )
                              }
                            </div>
                          );
                        })
                    }
                  </div>
                )
              }
            </FieldArray>
          </RBForm.Group>
          <div
            style={{
              float: 'right',
              bottom: 10,
              paddingBottom: 15,
              position: 'absolute',
              right: 30,
            }}
          >
            <Button variant="outline-secondary" onClick={() => onClose()}>Cancel</Button>
            &nbsp;
            {
              contest
                ? <Button type="submit" variant='success'>Save</Button>
                : <Button type="submit" variant='success'>Create</Button>
            }
          </div>
        </form>
      )}
    />
  ) : <div />;
};

export default ContestResultsForm;
