import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import links from 'utils/links';
import './style.scss';

const Footer = () => (
  <Container
    className="footer"
    fluid
  >
    <Row>
      <Col md={12}>
        &copy;
        {new Date().getFullYear()}&nbsp;
        <a href={links.tos()}>loserswinbig.com</a>
      </Col>
    </Row>
    <Row>
      <Col md={4}>
        <div className="text-left">
          <a href={links.about()}>About</a>
          <br />
          <a href={links.privacy()}>Privacy</a>
          <br />
          <a href={links.tos()}>Terms of Service</a>
        </div>
      </Col>
    </Row>
  </Container>
);

export default Footer;
