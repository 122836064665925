import React from 'react';
import { Link } from 'react-router-dom';
import { DateTime } from 'luxon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAuth } from 'services/login/hooks';
import links from 'utils/links';
import PostText from './PostText';

const Post = ({ post }) => {
  const auth = useAuth();

  return (
    <div style={{ textAlign: 'left' }}>
      <h1 style={{ color: '#d65d0c' }}>
        {post.title}
      </h1>
      <img
        alt={post.title}
        src={post.mainImage}
        style={{
          height: 300,
          margin: '10px 0',
          objectFit: 'cover',
          width: '100%',
        }}
      />
      <p style={{ color: '#a9a9a9' }}>
        <b>
          By&nbsp;
          <Link to={links.user(post.userName)}>
            {post.userName}
          </Link>
          &nbsp;
          {DateTime.fromISO(post.publishedAt).toRelative()}
          &nbsp;
          {
            auth.user.id === post.userId && (
              <Link to={links.adminPostUpsert(post.id)}>
                <FontAwesomeIcon
                  icon="edit"
                  size="1x"
                  style={{
                    color: 'blue',
                    cursor: 'pointer',
                  }}
                />
              </Link>
            )
          }
        </b>
      </p>
      <div
        className="fb-like"
        data-href={`/blog/posts/${post.urlId}`}
        data-url={`/blog/posts/${post.urlId}`}
        data-layout="button_count"
        data-action="like"
        data-size="large"
        data-share="false"
        style={{
          marginLeft: -32,
        }}
      />
      <PostText
        text={post.text}
      />
    </div>
  );
};

export default Post;
