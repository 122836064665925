import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import SlidingPane from 'react-sliding-pane';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateTime } from 'luxon';
import humanizeDuration from 'humanize-duration';
import useInterval from 'use-interval';
import PredictionForm from 'components/predictions/PredictionForm';
import { Contest } from 'services/contests/types';
import { useGetPredictionsQuery } from 'services/predictions/api';
import links from 'utils/links';
import ContestStatus from './ContestStatus';

const ContestPreview = ({ contest }) => {
  const [, setNow] = useState(DateTime.now());
  const [predictingContest, setPredictingContest] = useState<Contest | null>(null);
  const { data: predictions } = useGetPredictionsQuery();

  useInterval(() => {
    setNow(DateTime.now());
  }, 60000);

  const now = DateTime.now();
  const startsAt = DateTime.fromISO(contest.startsAt);
  const endsAt = DateTime.fromISO(contest.endsAt);
  const isOpen = now >= startsAt && now < endsAt;
  const startsIn = now < startsAt && (startsAt.toMillis() - now.toMillis());
  const prediction = predictions?.find(p => p.contestId === contest.id);
  const isEntered = !!prediction;

  return (
    <div
      style={{
        ...(isOpen && { backgroundColor: '#c6e2cb' }),
        ...(!isOpen && { backgroundColor: '#e7e7e7' }),
        borderRadius: 5,
        margin: '10px 0',
        padding: 10,
        textAlign: 'left',
      }}
    >
      <table>
        <tbody>
          <tr>
            <td>
              {
                contest.mainImage && (
                  <img
                    alt={contest.title}
                    src={contest.mainImage}
                    style={{
                      height: 80,
                      margin: '10px 10px 10px 0',
                      objectFit: 'cover',
                      width: 120,
                    }}
                  />
                )
              }
            </td>
            <td
              style={{
                verticalAlign: 'top',
                width: '100%',
              }}
            >
              {
                isOpen && (
                  <Button
                    onClick={() => setPredictingContest(contest)}
                    style={{
                      float: 'right',
                      marginLeft: 30,
                    }}
                    variant={isEntered ? 'success' : 'primary'}
                  >
                    {
                      isEntered
                        ? (
                          <>
                            <FontAwesomeIcon
                              icon="check"
                              size="1x"
                            />
                            &nbsp;
                            Entered
                          </>
                        )
                        : 'Enter'
                    }
                    { startsIn && ` in ${humanizeDuration(startsIn, { round: true, units: ['d', 'h', 'm'] })}` }
                  </Button>
                )
              }
              <h4>
                <Link to={links.contest(contest.urlId)}>
                  {contest.title}
                </Link>
              </h4>
              <ContestStatus contest={contest} />
              {
                isOpen && (
                  <div>
                    {contest.nbContestants} contestants
                  </div>
                )
              }
            </td>
          </tr>
        </tbody>
      </table>
      {
        predictingContest && (
          <SlidingPane
            isOpen={!!predictingContest}
            onRequestClose={() => setPredictingContest(null)}
            overlayClassName="slidingPane_overlay"
          >
            <PredictionForm
              contest={predictingContest}
              onClose={() => setPredictingContest(null)}
              prediction={prediction}
            />
          </SlidingPane>
        )
      }
    </div>
  );
};

export default ContestPreview;
