import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import { DateTime } from 'luxon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { selectCurrentUser } from 'services/login/slice';
import links from 'utils/links';

const ApiKeysList = () => {
  const user = useSelector(selectCurrentUser);

  return (
    <Table
      responsive
      striped
      bordered
      hover
    >
      <thead>
        <tr>
          <th style={{ width: 250 }}>Name</th>
          <th>Permissions</th>
          <th style={{ width: 100 }}>Created</th>
          <th style={{ width: 100 }}>Expires</th>
          <th style={{ width: 30 }}>&nbsp;</th>
          <th style={{ width: 30 }}>&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        {
          user.apiKeys?.map(apiKey => (
            <tr key={apiKey.name}>
              <td>
                {apiKey.name}
              </td>
              <td>
                {
                  apiKey.permissions
                    .map(permission => (
                      <div key={permission}>
                        {permission}
                      </div>
                    ))
                }
              </td>
              <td>
                {DateTime.fromISO(apiKey.createdAt).toRelative()}
              </td>
              <td>
                {DateTime.fromISO(apiKey.expiresAt).toRelative()}
              </td>
              <td>
                <Link to={links.adminPostUpsert(apiKey.name)}>
                  <FontAwesomeIcon
                    icon="edit"
                    size="1x"
                    style={{
                      color: 'blue',
                      cursor: 'pointer',
                    }}
                  />
                </Link>
              </td>
              <td>
                <FontAwesomeIcon
                  icon="times"
                  onClick={() => undefined}
                  size="lg"
                  style={{
                    color: 'red',
                    cursor: 'pointer',
                  }}
                />
              </td>
            </tr>
          ))
        }
      </tbody>
    </Table>
  );
};

export default ApiKeysList;
