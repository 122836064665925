import React from 'react';
import { Link } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import { DateTime } from 'luxon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { usePatchPostMutation } from 'services/posts/api';
import links from 'utils/links';

const PostsList = ({
  onDeletePost,
  posts,
}) => {
  const [patchPost] = usePatchPostMutation();

  const handleChangePublished = (e, postId) => {
    const { checked } = e.target;
    patchPost({
      id: postId,
      isPublished: checked,
    });
  };

  return (
    <Table
      responsive
      striped
      bordered
      hover
    >
      <thead>
        <tr>
          <th style={{ width: 65 }}>&nbsp;</th>
          <th>Title</th>
          <th>Author</th>
          <th style={{ width: 150 }}>Created</th>
          <th style={{ width: 100 }}>Published</th>
          <th style={{ width: 30 }}>&nbsp;</th>
          <th style={{ width: 30 }}>&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        {
          posts?.map(post => (
            <tr key={post.id}>
              <td
                style={{
                  ...(post.mainImage && {
                    backgroundImage: `url("${post.mainImage}")`,
                    backgroundSize: 'cover',
                  }),
                }}
              />
              <td>
                <Link to={links.post(post.urlId)}>
                  {post.title}
                </Link>
              </td>
              <td>
                <Link to={links.user(post.userName)}>
                  {post.userName}
                </Link>
              </td>
              <td>
                {DateTime.fromISO(post.createdAt).toRelative()}
              </td>
              <td>
                <input
                  defaultChecked={post.isPublished}
                  onChange={e => handleChangePublished(e, post.id)}
                  type="checkbox"
                />
              </td>
              <td>
                <Link to={links.adminPostUpsert(post.id)}>
                  <FontAwesomeIcon
                    icon="edit"
                    size="1x"
                    style={{
                      color: 'blue',
                      cursor: 'pointer',
                    }}
                  />
                </Link>
              </td>
              <td>
                <FontAwesomeIcon
                  icon="times"
                  onClick={() => onDeletePost(post)}
                  size="lg"
                  style={{
                    color: 'red',
                    cursor: 'pointer',
                  }}
                />
              </td>
            </tr>
          ))
        }
      </tbody>
    </Table>
  );
};

export default PostsList;
