import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import ReactNotification from 'react-notifications-component';
import useWebSocket from 'react-use-websocket';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-notifications-component/dist/theme.css';
import { PrivateOutlet } from 'components/PrivateOutlet';
import TopMenu from 'components/TopMenu';
import Footer from 'components/UI/Footer/Footer';
import AdminPage from 'pages/admin/AdminPage';
import AdminContestsPage from 'pages/admin/ContestsPage';
import AdminDataPage from 'pages/admin/DataPage';
import AdminFilesPage from 'pages/admin/FilesPage';
import AdminPostsPage from 'pages/admin/PostsPage';
import AdminPostUpsertPage from 'pages/admin/PostUpsertPage';
import AdminPredictionsPage from 'pages/admin/PredictionsPage';
import AdminPaymentsPage from 'pages/admin/PaymentsPage';
import AdminSettingsPage from 'pages/admin/SettingsPage';
import AdminUsersPage from 'pages/admin/UsersPage';
import LoginPage from 'pages/LoginPage';
import SignupPage from 'pages/SignupPage';
import VerifyPage from 'pages/VerifyPage';
import AboutPage from 'pages/home/AboutPage';
import BlogPage from 'pages/BlogPage';
import ContestPage from 'pages/ContestPage';
import ContestsPage from 'pages/ContestsPage';
import EventsPage from 'pages/EventsPage';
import EventPage from 'pages/EventPage';
import FightPage from 'pages/FightPage';
import FighterPage from 'pages/FighterPage';
import HomePage from 'pages/home/HomePage';
import PostPage from 'pages/PostPage';
import Page404 from 'pages/Page404';
import PrivacyPage from 'pages/PrivacyPage';
import ToSPage from 'pages/ToSPage';
import UserPage from 'pages/UserPage';
import { useLoginMutation } from 'services/login/api';
import { useAuth } from 'services/login/hooks';
import { setCredentials } from 'services/login/slice';
import envvars from 'utils/envvars';
import links from 'utils/links';
// import { initializeSocket } from 'utils/sockets';
import './App.scss';

library.add(fas);

// initializeSocket();

const App = () => {
  const auth = useAuth();
  const dispatch = useDispatch();

  const [login] = useLoginMutation();

  useEffect(() => {
    if (!auth.user) {
      login({}).unwrap()
        .then(u => {
          dispatch(setCredentials({ user: u }));
        });
    }
  }, [auth.user]);

  useWebSocket(envvars.websocketServer, {
    onOpen: () => {
      console.info('WebSocket connection established.');
    },
  });

  return (
    <div
      id="App"
      className="App"
    >
      <ReactNotification />
      <BrowserRouter>
        <div className="siteContent">
          <TopMenu />
          <Routes>
            <Route path='/' element={<HomePage />} />
            <Route path={links.paths.home} element={<HomePage />} />
            <Route path={links.paths.about} element={<AboutPage />} />
            <Route path={links.paths.contest} element={<ContestPage />} />
            <Route path={links.paths.contests} element={<ContestsPage />} />
            <Route path={links.paths.events} element={<EventsPage />} />
            <Route path={links.paths.event} element={<EventPage />} />
            <Route path={links.paths.fight} element={<FightPage />} />
            <Route path={links.paths.fighter} element={<FighterPage />} />
            <Route path={links.paths.user} element={<UserPage />} />
            <Route path={links.paths.post} element={<PostPage />} />
            <Route path={links.paths.blog} element={<BlogPage />} />
            <Route path={links.paths.login} element={<LoginPage />} />
            <Route path={links.signup()} element={<SignupPage />} />
            <Route path={links.verify()} element={<VerifyPage />} />
            <Route path={links.paths.privacy} element={<PrivacyPage />} />
            <Route path={links.paths.tos} element={<ToSPage />} />
            <Route element={<PrivateOutlet />}>
              <Route path={links.paths.adminContests} element={<AdminContestsPage />} />
              <Route path={links.adminData()} element={<AdminDataPage />} />
              <Route path={links.paths.adminFiles} element={<AdminFilesPage />} />
              <Route path={links.paths.adminPostUpsert} element={<AdminPostUpsertPage />} />
              <Route path={links.paths.adminPosts} element={<AdminPostsPage />} />
              <Route path={links.paths.adminPredictions} element={<AdminPredictionsPage />} />
              <Route path={links.paths.adminPayments} element={<AdminPaymentsPage />} />
              <Route path={links.paths.adminSettings} element={<AdminSettingsPage />} />
              <Route path={links.paths.adminUsers} element={<AdminUsersPage />} />
              <Route path={links.paths.admin} element={<AdminPage />} />
            </Route>
            <Route path='*' element={<Page404 />} />
          </Routes>
          <Footer />
        </div>
      </BrowserRouter>
    </div>
  );
};

export default App;
