import React from 'react';
import { useGetEventsQuery } from 'services/events/api';
import EventsList from './EventsList';

const UpcomingEventsList = () => {
  const { data: events } = useGetEventsQuery({});

  return (
    <EventsList
      events={events}
    />
  );
};

export default UpcomingEventsList;
