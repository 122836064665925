import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';

const Dropzone = ({
    onFileUpload,
}) => {
  const onDrop = useCallback(acceptedFiles => {
    onFileUpload(acceptedFiles);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      {
        isDragActive ?
          <p>Drop the files here ...</p> :
          <p>Drag 'n' drop some files here</p>
      }
    </div>
  )
}

export default Dropzone;
