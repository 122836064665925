import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import RBForm from 'react-bootstrap/Form';
import { Form, Field } from 'react-final-form';
import { useLoginMutation } from 'services/login/api';
import { setCredentials } from 'services/login/slice';
import type { LoginInfos } from 'services/login/types';
import links from 'utils/links';
import './style.scss';

const LoginPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showLoginError, setShowLoginError] = useState(false);

  const [login] = useLoginMutation();

  const [formState, setFormState] = useState<LoginInfos>({
    emailAddress: '',
    password: '',
  });

  const handleChange = ({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setFormState(prev => ({ ...prev, [name]: value }));
  };

  const onSubmit = async values => {
    login(values)
      .then(({ data: user }) => {
        if (!user) {
          throw new Error('Login failed');
        }
        dispatch(setCredentials({ user }));
        navigate('/');
      })
      .catch(() => setShowLoginError(true));
  };

  return (
    <div className="Login">
      {
        showLoginError && (
          <Alert variant="danger" >
            <strong>Invalid Connection</strong>
          </Alert>
        )
      }
      <Form
        initialValues={formState}
        keepDirtyOnReinitialize
        onSubmit={onSubmit}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <RBForm.Group>
              <RBForm.Label>Email address</RBForm.Label>
              <Field
                autoFocus
                className="form-control"
                component="input"
                name="emailAddress"
                onChange={e => handleChange(e)}
                type="text"
              />
            </RBForm.Group>
            <RBForm.Group>
              <RBForm.Label>Password</RBForm.Label>
              <Field
                className="form-control"
                component="input"
                name="password"
                onChange={e => handleChange(e)}
                type="password"
              />
            </RBForm.Group>
            <br />
            <div>
              <Button
                type="submit"
                variant="primary"
              >
                Log in
              </Button>
              &nbsp;
              or
              &nbsp;
              <Link to={links.signup()}>Create an account</Link>
            </div>
          </form>
        )}
      />
    </div>
  );
};

export default LoginPage;
