import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import envvars from 'utils/envvars';
import { Revision } from './types';

export const apiRevisions = createApi({
  reducerPath: 'revisionsApi',
  tagTypes: ['Revision'],
  baseQuery: fetchBaseQuery({
    baseUrl: (process.env.NODE_ENV === 'production')
      ? `${envvars.apiServer}/v1`
      : 'http://localhost:3000/v1/',
    credentials: 'include',
  }),
  endpoints: builder => ({
    getRevisions: builder.query<Revision[], string>({
      query: postId => `posts/${postId}/revisions`,
      transformResponse: (data: { revisions: Revision[] }) => data.revisions,
      transformErrorResponse: (response: { status: string | number }) => response.status,
      providesTags: ['Revision'],
    }),
    getRevision: builder.query<Revision, any>({
      query: ({ id, postId }) => `posts/${postId}/revisions/${id}`,
      transformResponse: (data: { revision: Revision }) => data.revision,
      transformErrorResponse: (response: { status: string | number }) => response.status,
      providesTags: ['Revision'],
    }),
    deleteRevision: builder.mutation<string, any>({
      query: ({ id, postId }) => ({
        url: `posts/${postId}/revisions/${id}`,
        method: 'DELETE',
      }),
      transformResponse: (data: { revisionId: string }) => data.revisionId,
      transformErrorResponse: (response: { status: string | number }) => response.status,
      invalidatesTags: ['Revision'],
    }),
  }),
});

export const {
  useGetRevisionsQuery,
  useGetRevisionQuery,
  useDeleteRevisionMutation,
} = apiRevisions;
