import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { Helmet } from 'react-helmet';
import PostPreview from 'components/blog/PostPreview';
import PredictionsList from 'components/predictions/PredictionsList';
import { useLazyGetPostsByUserQuery } from 'services/posts/api';
import { Post } from 'services/posts/types';
import { useLazyGetPredictionsByUserQuery } from 'services/predictions/api';
import { Prediction } from 'services/predictions/types';

const UserPage = () => {
  const { userName } = useParams();
  const [posts, setPosts] = useState<Post[]>([]);
  const [getPosts] = useLazyGetPostsByUserQuery();
  const [predictions, setPredictions] = useState<Prediction[]>([]);
  const [getPredictions] = useLazyGetPredictionsByUserQuery();

  useEffect(() => {
    if (userName) {
      getPosts(userName)
        .then(({ data }) => {
          if (data) {
            setPosts(data);
          }
        });
      getPredictions(userName)
        .then(({ data }) => {
          if (data) {
            setPredictions(data);
          }
        });
    }
  }, [userName]);

  return (
    <>
      <Helmet>
        <title>{ `LosersWinBig - ${userName}` }</title>
        <meta property="og:type" content="article" key="ogtype" />
        <meta property="og:title" content={`LosersWinBig - ${userName}`} key="ogtitle" />
        <meta property="og:site_name" content="LosersWinBig" key="ogsitename" />
        <meta property="og:url" content={window.location.href} key="ogurl" />
        <meta property="og:description" content={userName} key="ogdescription" />
      </Helmet>
      <Container
        fluid
        style={{
          paddingTop: 110,
        }}
      >
        <Row>
          <Col lg={12}>
            <Breadcrumb>
              <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
              <Breadcrumb.Item active>{userName}</Breadcrumb.Item>
            </Breadcrumb>
            <h3>{userName}</h3>
            <Tabs defaultActiveKey="POSTS">
              <Tab
                eventKey="POSTS"
                title="Posts"
              >
                {
                  posts?.filter(post => post.isPublished)
                    .map(post => (
                      <PostPreview
                        key={post.id}
                        post={post}
                      />
                    ))
                }
              </Tab>
              <Tab
                eventKey="PREDICTIONS"
                title="Predictions"
              >
                <PredictionsList
                  predictions={predictions}
                />
              </Tab>
            </Tabs>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default UserPage;
