import React from 'react';
import { Link } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import links from 'utils/links';

const FightsList = ({ fights }) => {
  return (
    <Table responsive striped bordered hover>
      <thead>
        <tr>
          <th style={{ width: 50 }}>#</th>
          <th>Fight</th>
          <th>Weight class</th>
        </tr>
      </thead>
      <tbody>
        {
          [...(fights || [])]
            .sort((fightA, fightB) => (fightA.no < fightB.no ? -1 : 1))
            .map((fight, i) => (
              <tr key={i}>
                <td>{fight.no}</td>
                <td style={{ textAlign: 'left' }}>
                  <Link to={links.fight(fight.id)}>
                    {fight.red.name} vs {fight.blue.name}
                  </Link>
                </td>
                <td>{fight.weightClass}</td>
              </tr>
            ))
        }
      </tbody>
    </Table>
  );
};

export default FightsList;
