import React from 'react';
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';
import ReactGA from 'react-ga4';
import { MetaMaskProvider } from "metamask-react";
import store from 'services/store';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';

ReactGA.initialize('G-SVDHZ0XXWX');

const rootElement = document.getElementById('root');
if (!rootElement) {
  throw new Error('Root element does not exist');
}
const root = createRoot(rootElement);

root.render(
  <React.StrictMode>
    <MetaMaskProvider>
      <Provider store={store}>
        <App />
      </Provider>
    </MetaMaskProvider>
  </React.StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
