import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ReactGA from 'react-ga4';
import { Helmet } from 'react-helmet';
import FightInfo from 'components/fights/FightInfo';
import LoadingGif from 'components/UI/LoadingGif';
import { useLazyGetFightQuery } from 'services/fights/api';
import { Fight } from 'services/fights/types';

const FightPage = () => {
  const location = useLocation();
  const { id: fightId } = useParams();
  const [fight, setFight] = useState<Fight | null>(null);
  const [getFight] = useLazyGetFightQuery();

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: location.pathname,
    });
  }, []);

  useEffect(() => {
    if (fightId) {
      getFight(fightId)
        .then(({ data }) => {
          if (data) {
            setFight(data);
          }
        });
    }
  }, [fightId]);

  if (!fight) {
    return <LoadingGif />;
  }
  return (
    <>
      <Helmet>
        <title>LosersWinBig - {`${fight?.red.name} vs ${fight?.blue.name}`}</title>
      </Helmet>
      <Container
        fluid
        style={{
          paddingTop: 110,
        }}
      >
        <Row>
          <Col xl={10} lg={12}>
            <h3>
              { fight?.date }
              :&nbsp;
              { fight?.red.name }
              &nbsp;vs&nbsp;
              { fight?.blue.name }
            </h3>
            <h4>{ fight?.weightClass } Bout</h4>
            <br />
            <FightInfo
              fight={fight}
            />
          </Col>
          <Col xl={2}></Col>
        </Row>
      </Container>
    </>
  );
};

export default FightPage;
