import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import envvars from 'utils/envvars';
import { Prediction } from './types';

export const apiPredictions = createApi({
  reducerPath: 'predictionsApi',
  tagTypes: ['Prediction'],
  baseQuery: fetchBaseQuery({
    baseUrl: (process.env.NODE_ENV === 'production')
      ? `${envvars.apiServer}/v1`
      : 'http://localhost:3000/v1/',
    credentials: 'include',
  }),
  endpoints: builder => ({
    getPredictions: builder.query<Prediction[], void>({
      query: () => 'predictions',
      transformResponse: (data: { predictions: Prediction[] }) => data.predictions,
      transformErrorResponse: (response: { status: string | number }) => response.status,
      providesTags: ['Prediction'],
    }),
    getPredictionsByUser: builder.query<Prediction[], string>({
      query: userName => ({
        url: 'predictions',
        params: {
          userName,
        },
      }),
      transformResponse: (data: { predictions: Prediction[] }) => data.predictions,
      transformErrorResponse: (response: { status: string | number }) => response.status,
      providesTags: ['Prediction'],
    }),
    getPrediction: builder.query<Prediction, string>({
      query: id => `predictions/${id}`,
      transformResponse: (data: { prediction: Prediction }) => data.prediction,
      transformErrorResponse: (response: { status: string | number }) => response.status,
      providesTags: ['Prediction'],
    }),
    addPrediction: builder.mutation<Prediction, Partial<Prediction>>({
      query: body => ({
        url: 'predictions',
        method: 'POST',
        body,
      }),
      transformResponse: (data: { prediction: Prediction }) => data.prediction,
      transformErrorResponse: (response: { status: string | number }) => response.status,
      invalidatesTags: ['Prediction'],
    }),
    patchPrediction: builder.mutation<Prediction, Partial<Prediction> & Pick<Prediction, 'id'>>({
      query: ({ id, ...body }) => ({
        url: `predictions/${id}`,
        method: 'PATCH',
        body,
      }),
      transformResponse: (data: { prediction: Prediction }) => data.prediction,
      transformErrorResponse: (response: { status: string | number }) => response.status,
      invalidatesTags: ['Prediction'],
    }),
    updatePrediction: builder.mutation<Prediction, Partial<Prediction> & Pick<Prediction, 'id'>>({
      query: ({ id, ...body }) => ({
        url: `predictions/${id}`,
        method: 'PUT',
        body,
      }),
      transformResponse: (data: { prediction: Prediction }) => data.prediction,
      transformErrorResponse: (response: { status: string | number }) => response.status,
      invalidatesTags: ['Prediction'],
    }),
    deletePrediction: builder.mutation<string, string>({
      query: id => ({
        url: `predictions/${id}`,
        method: 'DELETE',
      }),
      transformResponse: (data: { predictionId: string }) => data.predictionId,
      transformErrorResponse: (response: { status: string | number }) => response.status,
      invalidatesTags: ['Prediction'],
    }),
  }),
});

export const {
  useGetPredictionsQuery,
  useLazyGetPredictionsByUserQuery,
  useGetPredictionQuery,
  useAddPredictionMutation,
  usePatchPredictionMutation,
  useUpdatePredictionMutation,
  useDeletePredictionMutation,
} = apiPredictions;
