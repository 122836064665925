import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga4';
import { DateTime } from 'luxon';
import useInterval from 'use-interval';
import ContestPreview from 'components/contests/ContestPreview';
import FacebookPagePlugin from 'components/UI/FacebookPagePlugin';
import { useGetContestsQuery } from 'services/contests/api';

const ContestsPage = () => {
  const location = useLocation();
  const [, setNow] = useState(DateTime.now());
  const { data: contests } = useGetContestsQuery();

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: location.pathname,
    });
  }, []);

  useInterval(() => {
    setNow(DateTime.now());
  }, 60000);

  return (
    <>
      <Helmet>
        <title>LosersWinBig - Contests</title>
      </Helmet>
      <Container
        fluid
        style={{
          paddingTop: 110,
        }}
      >
        <Row>
          <Col md lg={9}>
            <Breadcrumb>
              <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
              <Breadcrumb.Item active>Contests</Breadcrumb.Item>
            </Breadcrumb>
            <h3>Contests</h3>
            <Tabs defaultActiveKey="CURRENT">
              <Tab
                eventKey="CURRENT"
                title="Current"
              >
                {
                  contests?.filter(c => DateTime.fromISO(c.endsAt) >= DateTime.now())
                    .sort((a, b) => (a.endsAt < b.endsAt ? -1 : 1))
                    .map(contest => (
                      <ContestPreview
                        key={contest.id}
                        contest={contest}
                      />
                    ))
                }
              </Tab>
              <Tab
                eventKey="PAST"
                title="Past"
              >
                {
                  contests?.filter(c => DateTime.fromISO(c.endsAt) < DateTime.now())
                    .sort((a, b) => (a.endsAt > b.endsAt ? -1 : 1))
                    .map(contest => (
                      <ContestPreview
                        key={contest.id}
                        contest={contest}
                      />
                    ))
                }
              </Tab>
            </Tabs>
          </Col>
          <Col lg={3}>
            <h3>Follow loserswinbig</h3>
            <FacebookPagePlugin />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ContestsPage;
