import React, { useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import ReactGA from 'react-ga4';
import { Helmet } from 'react-helmet';
import FacebookPagePlugin from 'components/UI/FacebookPagePlugin';
import './style.scss';

const AboutPage = () => {
  useEffect(() => {
    ReactGA.send('pageview');
  }, []);

  return (
    <>
      <Helmet>
        <title>LosersWinBig - About</title>
      </Helmet>
      <Container
        fluid
        style={{
          paddingTop: 110,
        }}
      >
        <Row>
          <Col md lg={9}>
            <Breadcrumb>
              <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
              <Breadcrumb.Item active>About</Breadcrumb.Item>
            </Breadcrumb>
            <div
              style={{
                textAlign: 'left',
              }}
            >
              <h3>About</h3>
              <h5>Scoring System for Predictions</h5>
              <div>
                <p>I use a simple 10-point scoring system for each prediction, using the following rules:</p>
                <p><b>If the fight ends in a finish:</b></p>
                <ul>
                  <li>10 points if I correctly predicted the winner, how the fight ended, and the round</li>
                  <li>9 points if I correctly predicted the winner and either how the fight ended or the round</li>
                  <li>8 points if I correctly predicted the winner and that the fight would end in a finish</li>
                  <li>5 points if I correctly predicted the winner only</li>
                  <li>0 points otherwise</li>
                </ul>
                <p><b>If the fight ends in a decision (no draw, no NC, and no DQ):</b></p>
                <ul>
                  <li>10 points if I correctly predicted the winner and that the fight would end in a decision</li>
                  <li>5 points if I correctly predicted the winner only</li>
                  <li>4 points if it is a split decision and I predicted a decision but picked the wrong winner</li>
                  <li>2 points if it is a unanimous decision and I predicted a decision but picked the wrong winner</li>
                  <li>0 points otherwise</li>
                </ul>
                <p><b>If the fight ends in a draw:</b></p>
                <ul>
                  <li>5 points if I predicted the fight would end in a decision</li>
                  <li>0 points otherwise</li>
                </ul>
                <p><b>If the fight is canceled or ends in a no-contest (NC) or a disqualification (DQ):</b></p>
                <ul>
                  <li>No score</li>
                </ul>
              </div>
            </div>
          </Col>
          <Col lg={3}>
            <h3>Follow loserswinbig</h3>
            <FacebookPagePlugin />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AboutPage;
