export const weightClassToPounds = (weightClass: string) => {
  if (weightClass.includes('Strawweight')) return 115;
  if (weightClass.includes('Flyweight')) return 125;
  if (weightClass.includes('Bantamweight')) return 135;
  if (weightClass.includes('Featherweight')) return 145;
  if (weightClass === 'Lightweight') return 155;
  if (weightClass === 'Welterweight') return 170;
  if (weightClass === 'Middleweight') return 185;
  if (weightClass === 'Light Heavyweight') return 205;
  if (weightClass === 'Heavyweight') return 265;
};
