const home = () => '/home';
const about = () => '/about';
const login = () => '/login';
const signup = () => '/signup';
const privacy = () => '/privacy';
const tos = () => '/tos';
const verify = (emailAddress?: string, code?: string) => {
  const emailAddressComponent = emailAddress && `emailAddress=${emailAddress}`;
  const codeComponent = code && `code=${code}`;
  const queryParams = [emailAddressComponent, codeComponent]
    .filter(c => !!c)
    .join('&');
  return `/verify${queryParams ? `?${queryParams}` : ''}`;
};
const events = () => '/events';
const event = (id: string) => `/event/${id}`;
const fight = (id: string) => `/fight/${id}`;
const fighter = (id: string) => `/fighter/${id}`;
// User
const user = (userName: string) => `/users/${userName}`;
// Blog
const blog = () => '/blog';
const post = (urlId: string) => `/blog/posts/${urlId}`;
// Contest
const contest = (urlId: string) => `/contests/${urlId}`;
const contests = () => '/contests';
// Admin
const admin = () => '/admin';
const adminContests = () => '/admin/contests';
const adminData = (filter: { [key: string]: any; } = {}) => {
  if (filter.from) {
    return `/admin/data?from=${filter.from}`;
  }
  if (filter.fighterId) {
    return `/admin/data?fighterId=${filter.fighterId}`;
  }
  return '/admin/data';
};
const adminFiles = () => '/admin/files';
const adminPosts = () => '/admin/posts';
const adminPostUpsert = (id?: string) => (id
  ? `/admin/posts/upsert/${id}`
  : '/admin/posts/upsert');
const adminPredictions = () => '/admin/predictions';
const adminPayments = () => '/admin/payments';
const adminSettings = () => '/admin/settings';
const adminUsers = () => '/admin/users';

const paths = {
  home: home(),
  about: about(),
  login: login(),
  signup: signup(),
  verify: verify(':emailAddress?', ':code?'),
  privacy: privacy(),
  tos: tos(),
  events: events(),
  event: event(':id'),
  fight: fight(':id'),
  fighter: fighter(':id'),
  // User
  user: user(':userName'),
  // Blog
  blog: blog(),
  post: post(':urlId'),
  // Contest
  contest: contest(':urlId'),
  contests: contests(),
  // Admin
  admin: admin(),
  adminContests: adminContests(),
  adminData,
  adminFiles: adminFiles(),
  adminPosts: adminPosts(),
  adminPostUpsert: adminPostUpsert(':id?'),
  adminPredictions: adminPredictions(),
  adminPayments: adminPayments(),
  adminSettings: adminSettings(),
  adminUsers: adminUsers(),
};

export default {
  home,
  about,
  login,
  signup,
  verify,
  privacy,
  tos,
  events,
  event,
  fight,
  fighter,
  // User
  user,
  // Blog
  blog,
  post,
  // Contest
  contest,
  contests,
  // Admin
  admin,
  adminContests,
  adminData,
  adminFiles,
  adminPosts,
  adminPostUpsert,
  adminPredictions,
  adminPayments,
  adminSettings,
  adminUsers,
  paths,
};
