import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import './style.scss';

type Id = string | number;

interface RadioButtonsProps {
  defaultSelected?: Id;
  onOptionClick: (id: Id) => void;
  options: { id: Id; name: string; }[];
}

const RadioButtons = ({
  defaultSelected,
  onOptionClick,
  options,
}: RadioButtonsProps) => {
  const [selectedOption, setSelectedOption] = useState<Id | null>(defaultSelected || null);

  return (
    <div>
      {
        options
          .map(option => {
            return option && option.id && (
              <Button
                key={option.id}
                onClick={() => {
                  setSelectedOption(option.id);
                  onOptionClick(option.id);
                }}
                style={{
                  backgroundColor: '#d65d0c',
                  borderColor: '#d65d0c',
                  boxShadow: 'none',
                  margin: 3,
                  minWidth: 50,
                  opacity: (selectedOption === option.id) ? 1 : 0.5,
                }}
              >
                { option.name }
              </Button>
            );
          })
      }
    </div>
  );
};

export default RadioButtons;
