import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import SideNav, { NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';
import ClickOutside from 'react-click-outside';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAuth } from 'services/login/hooks';
import links from 'utils/links';
import '@trendmicro/react-sidenav/dist/react-sidenav.css';

const menuItems = {
  CONTESTS: 'contests',
  DASHBOARD: 'dashboard',
  DATA: 'data',
  POSTS: 'posts',
  PREDICTIONS: 'predictions',
  PAYMENTS: 'payments',
  SETTINGS: 'settings',
  USERS: 'users',
};

const Sidebar = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [expanded, setExpanded] = useState(false);

  const getDefaultSelectedMenuItem = () => {
    if (location.pathname === links.adminContests()) return menuItems.CONTESTS;
    if (location.pathname === links.admin()) return menuItems.DASHBOARD;
    if (location.pathname === links.adminData()) return menuItems.DATA;
    if (location.pathname === links.adminPosts()) return menuItems.POSTS;
    if (location.pathname === links.adminPredictions()) return menuItems.PREDICTIONS;
    if (location.pathname === links.adminPayments()) return menuItems.PAYMENTS;
    if (location.pathname === links.adminSettings()) return menuItems.SETTINGS;
    if (location.pathname === links.adminUsers()) return menuItems.USERS;
    return null;
  };

  return (
    <ClickOutside
      onClickOutside={() => {
        setExpanded(false);
      }}
    >
      <SideNav
        expanded={expanded}
        onSelect={selected => {

          const to = (selected !== menuItems.DASHBOARD)
            ? `/admin/${selected}`
            : '/admin';
          if (location.pathname !== to) {
            navigate(to);
          }
        }}
        onToggle={expanded => setExpanded(expanded)}
        style={{
          backgroundColor: '#d65d0c',
          position: 'fixed',
          top: 96,
          zIndex: 10,
        }}
      >
        <SideNav.Toggle />
        <SideNav.Nav defaultSelected={getDefaultSelectedMenuItem() || menuItems.DASHBOARD}>
          <NavItem eventKey={menuItems.DASHBOARD}>
            <NavIcon>
              <FontAwesomeIcon
                icon="bar-chart"
                size="lg"
              />
            </NavIcon>
            <NavText>
              Dashboard
            </NavText>
          </NavItem>
          <NavItem eventKey={menuItems.POSTS}>
            <NavIcon>
              <FontAwesomeIcon
                icon="sticky-note"
                size="lg"
              />
            </NavIcon>
            <NavText>
              Posts
            </NavText>
          </NavItem>
          <NavItem eventKey={menuItems.CONTESTS}>
            <NavIcon>
              <FontAwesomeIcon
                icon="trophy"
                size="lg"
              />
            </NavIcon>
            <NavText>
              Contests
            </NavText>
          </NavItem>
          <NavItem eventKey={menuItems.PREDICTIONS}>
            <NavIcon>
              <FontAwesomeIcon
                icon="check"
                size="lg"
              />
            </NavIcon>
            <NavText>
              Predictions
            </NavText>
          </NavItem>
          <NavItem eventKey={menuItems.DATA}>
            <NavIcon>
              <FontAwesomeIcon
                icon="database"
                size="lg"
              />
            </NavIcon>
            <NavText>
              Données
            </NavText>
          </NavItem>
          <NavItem eventKey={menuItems.PAYMENTS}>
            <NavIcon>
              <FontAwesomeIcon
                icon="money-check"
                size="lg"
              />
            </NavIcon>
            <NavText>
              Payments
            </NavText>
          </NavItem>
          {
            auth.user.permissions?.includes('users.list') && (
              <NavItem eventKey={menuItems.USERS}>
                <NavIcon>
                  <FontAwesomeIcon
                    icon="users"
                    size="lg"
                  />
                </NavIcon>
                <NavText>
                  Users
                </NavText>
              </NavItem>
            )
          }
          <NavItem eventKey={menuItems.SETTINGS}>
            <NavIcon>
              <FontAwesomeIcon
                icon="cog"
                size="lg"
              />
            </NavIcon>
            <NavText>
              Settings
            </NavText>
          </NavItem>
        </SideNav.Nav>
      </SideNav>
    </ClickOutside>
  );
};

export default Sidebar;
