import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import SlidingPane from 'react-sliding-pane';
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga4';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateTime } from 'luxon';
import humanizeDuration from 'humanize-duration';
import useInterval from 'use-interval';
import ContestStatus from 'components/contests/ContestStatus';
import PredictionForm from 'components/predictions/PredictionForm';
import { useGetContestsQuery } from 'services/contests/api';
import { Contest } from 'services/contests/types';
import { useGetPredictionsQuery } from 'services/predictions/api';
import links from 'utils/links';

const ContestPage = () => {
  const location = useLocation();
  const { urlId } = useParams();
  const [, setNow] = useState(DateTime.now());
  const [predictingContest, setPredictingContest] = useState<Contest | null>(null);
  const { data: contests } = useGetContestsQuery();
  const { data: predictions } = useGetPredictionsQuery();
  const contest = contests?.find(p => p.urlId === urlId);
  const prediction = predictions?.find(p => p.contestId === contest?.id);
  const isEntered = !!prediction;

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: location.pathname,
    });
  }, []);

  useInterval(() => {
    setNow(DateTime.now());
  }, 60000);

  const now = DateTime.now();
  const startsAt = DateTime.fromISO(contest?.startsAt);
  const endsAt = DateTime.fromISO(contest?.endsAt);
  const isOpen = now >= startsAt && now < endsAt;
  const startsIn = now < startsAt && (startsAt.toMillis() - now.toMillis());

  return contest ? (
    <>
      <Helmet>
        <title>{ `LosersWinBig - ${contest.title}` }</title>
        <meta property="og:type" content="article" key="ogtype" />
        <meta property="og:title" content={`LosersWinBig - ${contest.title}`} key="ogtitle" />
        <meta property="og:site_name" content="LosersWinBig" key="ogsitename" />
        <meta property="og:url" content={window.location.href} key="ogurl" />
        <meta property="og:description" content={contest.title} key="ogdescription" />
        <meta property="og:image" content={contest.mainImage} key="ogimage" />
      </Helmet>
      <Container
        fluid
        style={{
          paddingTop: 110,
        }}
      >
        <Row>
          <Col lg={12}>
            <Breadcrumb>
              <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
              <Breadcrumb.Item href={links.contests()}>Contests</Breadcrumb.Item>
              <Breadcrumb.Item active>{contest.title}</Breadcrumb.Item>
            </Breadcrumb>
            <div
              style={{
                height: 150,
                position: 'relative',
              }}
            >
              <h1
                style={{
                  color: '#d65d0c',
                  display: 'table-cell',
                  marginLeft: 15,
                  marginTop: 100,
                  position: 'absolute',
                  zIndex: 1,
                }}
              >
                {contest.title}
                <Button
                  disabled={!isOpen}
                  onClick={() => setPredictingContest(contest)}
                  style={{
                    marginLeft: 30,
                  }}
                  variant={isEntered ? 'success' : 'primary'}
                >
                  {
                    isEntered
                      ? (
                        <>
                          <FontAwesomeIcon
                            icon="check"
                            size="1x"
                          />
                          &nbsp;
                          Entered
                        </>
                      )
                      : 'Enter'
                  }
                  { startsIn && ` in ${humanizeDuration(startsIn, { round: true, units: ['d', 'h', 'm'] })}`}
                </Button>
              </h1>
              {
                contest.mainImage && (
                  <img
                    alt={contest.title}
                    src={contest.mainImage}
                    style={{
                      height: '100%',
                      filter: 'grayscale(50%) brightness(40%)',
                      margin: '10px 0',
                      objectFit: 'cover',
                      position: 'absolute',
                      width: '100%',
                      zIndex: 0,
                    }}
                  />
                )
              }
            </div>
            <br />
            <div
              className="fb-like"
              data-href={`/contests/${contest.urlId}`}
              data-url={`/contests/${contest.urlId}`}
              data-layout="button_count"
              data-action="like"
              data-size="large"
              data-share="false"
              style={{
                marginLeft: -32,
              }}
            />
            <ContestStatus contest={contest} />
            <Tabs defaultActiveKey="CONTESTANTS">
              <Tab
                eventKey="CONTESTANTS"
                title="Contestants"
              >
              </Tab>
            </Tabs>
            {
              predictingContest && (
                <SlidingPane
                  isOpen={!!predictingContest}
                  onRequestClose={() => setPredictingContest(null)}
                  overlayClassName="slidingPane_overlay"
                >
                  <PredictionForm
                    contest={predictingContest}
                    onClose={() => setPredictingContest(null)}
                    prediction={prediction}
                  />
                </SlidingPane>
              )
            }
          </Col>
        </Row>
      </Container>
    </>
  ) : null;
};

export default ContestPage;
