import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import RBForm from 'react-bootstrap/Form';
import { Form, Field } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { useAddPaymentMutation } from 'services/payments/api';

const PaymentForm = ({
  onClose,
  paymentId,
}) => {
  const [noPage, setNoPage] = useState(1);
  console.info(paymentId);

  const [addPayment] = useAddPaymentMutation();

  const onSubmit = async values => {
    values.amount = parseInt(values.amount, 10);
    addPayment(values)
      .then(() => setNoPage(2));
  };

  return noPage === 1 ? (
    <Form
      mutators={{ ...arrayMutators }}
      onSubmit={onSubmit}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <RBForm.Group>
            <RBForm.Label>Amount</RBForm.Label>
            <Field
              name="amount"
              component="input"
              className="form-control"
              style={{ width: 100 }}
            />
          </RBForm.Group>
          <div
            style={{
              float: 'right',
              bottom: 10,
              paddingBottom: 15,
              position: 'absolute',
              right: 30,
            }}
          >
            <Button variant="outline-secondary" onClick={() => onClose()}>Cancel</Button>
            &nbsp;
            <Button type="submit" variant='success'>Add Funds</Button>
          </div>
        </form>
      )}
    />
  ) : <div />;
};

export default PaymentForm;
