import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ReactGA from 'react-ga4';
import UpcomingEventsList from 'components/events/UpcomingEventsList';

const EventsPage = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: location.pathname,
    });
  }, []);

  return (
    <Container
      fluid
      style={{
        paddingTop: 110,
      }}
    >
      <Row>
        <Col xl={10} lg={12}>
          <h3>Events</h3>
          <UpcomingEventsList />
        </Col>
        <Col xl={2}></Col>
      </Row>
    </Container>
  );
};

export default EventsPage;
