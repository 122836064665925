import React from 'react';

const FacebookPagePlugin = () => {
  return (
    <div
      className="fb-page"
      data-href="https://www.facebook.com/loserswinbig/"
      data-tabs="timeline"
      data-width=""
      data-height=""
      data-small-header="false"
      data-adapt-container-width="true"
      data-hide-cover="false"
      data-show-facepile="true"
    >
      <blockquote cite="https://www.facebook.com/loserswinbig/" className="fb-xfbml-parse-ignore">
        <a href="https://www.facebook.com/loserswinbig/">LosersWinBig</a>
      </blockquote>
    </div>
  );
};

export default FacebookPagePlugin;
