import React from 'react';
import { Link } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateTime } from 'luxon';
import { useDeleteContestMutation } from 'services/contests/api';
import { Contest } from 'services/contests/types';
import links from 'utils/links';

interface ContestsListProps {
  contests?: Contest[],
  onEditContest?: (contestId: string) => void,
  onEditContestResults?: (contestId: string) => void,
}

const ContestsList = ({
  contests,
  onEditContest,
  onEditContestResults,
}: ContestsListProps) => {
  const [deleteContest] = useDeleteContestMutation();

  return (
    <Table
      responsive
      striped
      bordered
      hover
    >
      <thead>
        <tr>
          <th style={{ width: 65 }}>&nbsp;</th>
          <th>Title</th>
          <th>Status</th>
          <th style={{ width: 80 }}>Contestants</th>
          <th style={{ width: 80 }}>Value</th>
          <th style={{ width: 60 }}>&nbsp;</th>
          <th style={{ width: 30 }}>&nbsp;</th>
          { onEditContest && <th style={{ width: 30 }}>&nbsp;</th> }
          <th style={{ width: 30 }}>&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        {
          [...(contests || [])]
            .sort((a, b) => (a.endsAt < b.endsAt ? -1 : 1))
            .map(contest => {
              let status;
              if (contest.isOpen) {
                status = `Ends ${DateTime.fromISO(contest.endsAt).toRelative()}`;
              } else if (contest.hasEnded) {
                status = `Ended ${DateTime.fromISO(contest.endsAt).toRelative()}`;
              } else if (!contest.hasStarted) {
                status = `Starts ${DateTime.fromISO(contest.startsAt).toRelative()}`;
              }
              return (
                <tr key={contest.id}>
                  <td
                    style={{
                      ...(contest.mainImage && {
                        backgroundImage: `url("${contest.mainImage}")`,
                        backgroundSize: 'cover',
                      }),
                    }}
                  />
                  <td>
                    {contest.title}
                  </td>
                  <td>
                    {status}
                  </td>
                  <td>
                    {contest.nbContestants}
                  </td>
                  <td>
                    {contest.betValue}$
                  </td>
                  <td>
                    <Link to={links.contest(contest.urlId)}>
                      See
                    </Link>
                  </td>
                  <td>
                    <FontAwesomeIcon
                      icon="list"
                      onClick={onEditContestResults && (() => onEditContestResults(contest.id))}
                      size="1x"
                      style={{
                        color: 'blue',
                        cursor: 'pointer',
                      }}
                    />
                  </td>
                  {
                    onEditContest && (
                      <td>
                        <FontAwesomeIcon
                          icon="edit"
                          onClick={() => onEditContest(contest.id)}
                          size="1x"
                          style={{
                            color: 'blue',
                            cursor: 'pointer',
                          }}
                        />
                      </td>
                    )
                  }
                  <td>
                    <FontAwesomeIcon
                      icon="times"
                      onClick={() => deleteContest(contest.id)}
                      size="lg"
                      style={{
                        color: 'red',
                        cursor: 'pointer',
                      }}
                    />
                  </td>
                </tr>
              );
            })
        }
      </tbody>
    </Table>
  );
};

export default ContestsList;
