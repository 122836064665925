import htmlColors from 'html-colors';

export const invertColor = (color: string): string => {
  const hexColorRegex = /^#(?:[0-9a-fA-F]{3}){1,2}$/;
  let hexToUse: string | null = null;
  if (hexColorRegex.test(color)) {
    hexToUse = color.slice(1);
  } else {
    hexToUse = htmlColors.hex(color);
  }
  if (hexToUse) {
    const r = parseInt(hexToUse.slice(0, 2), 16);
    const g = parseInt(hexToUse.slice(2, 4), 16);
    const b = parseInt(hexToUse.slice(4, 6), 16);

    return (r * 0.299 + g * 0.587 + b * 0.114) > 180
      ? '#000000'
      : '#FFFFFF';
  }
  return '#000000';
};

export default {
  invertColor,
};
