import React from 'react';
import { Link } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import { Duration } from 'luxon';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import dedent from 'dedent-js';
import { useGetFightsForFighterQuery } from 'services/fights/api';
import { weightClassToPounds } from 'utils/fights/weightClasses';
import links from 'utils/links';

export interface LastFightsListProps {
  fighterId: string;
  name?: string;
}

const LastFightsList = ({
  fighterId,
  name,
}: LastFightsListProps) => {
  const { data: previousFights } = useGetFightsForFighterQuery(fighterId);

  const previousFightsWithCalculatedValues = [...(previousFights || [])]
    .map(lastFight => {
      const isTitle = lastFight.title;
      const { date, weightClass } = lastFight;
      const color = lastFight.red.id === fighterId ? 'red' : 'blue';
      const opponentColor = color === 'red' ? 'blue' : 'red';
      const opponent = lastFight[opponentColor];
      const isWin = lastFight.winner === color;
      const isDraw = lastFight.winner === 'draw';
      const isNoContest = lastFight.winner === 'noContest';
      const isLoss = lastFight.winner === opponentColor;
      const hasNoWinner = !isWin && !isDraw && !isNoContest && !isLoss;
      return {
        ...lastFight,
        date,
        hasNoWinner,
        isWin,
        isDraw,
        isNoContest,
        isLoss,
        isTitle,
        opponent,
        weightClass,
      };
    });

  const lastFightsTextToCopy = previousFightsWithCalculatedValues
    .map(lastFight => (
      `<tr style={{ color: "${lastFight.isWin ? 'green' : (lastFight.isDraw || lastFight.isNoContest ? 'gray' : 'red')}" }}>
            <td>${lastFight.isWin ? 'W' : (lastFight.isDraw ? 'D' : (lastFight.isNoContest ? 'NC' : 'L'))}</td>
            <td>${lastFight.date}</td>
            <td>${lastFight.opponent.name}</td>
            <td>${lastFight.nbRounds}</td>
            <td>${Duration.fromObject({ seconds: lastFight.lastRoundDuration }).toFormat('m:ss')}</td>
            <td>${lastFight.method}</td>
          </tr>
          `
    )).join('');

  return previousFights ? (
    <CopyToClipboard
      text={dedent(
        `<FightTable title="${name} - Last 5 fights" width={500}>
          ${lastFightsTextToCopy}
        </FightTable>`)}
    >
      <Table
        responsive
        striped={false}
        style={{
          marginBottom: 0,
          overflowX: 'visible',
        }}
      >
        <tbody>
          {
            previousFightsWithCalculatedValues
              .map(lastFight => {
                const {
                  date,
                  isTitle,
                  weightClass,
                  opponent,
                  isWin,
                  isDraw,
                  isNoContest,
                  isLoss,
                } = lastFight;
                let color = 'black';
                if (isWin) color = 'green';
                if (isDraw || isNoContest) color = 'gray';
                if (isLoss) color = 'red';
                return (
                  <tr
                    key={lastFight.id}
                    style={{
                      color,
                      ...(isTitle === 'C' && { backgroundColor: '#FFF6CF' }),
                    }}
                  >
                    <td width={120}>{ date }</td>
                    <td width={80}>{ weightClassToPounds(weightClass) } lbs</td>
                    <td width={30}>{ isWin ? 'W' : (isDraw ? 'D' : (isNoContest ? 'NC' : (isLoss && 'L'))) }</td>
                    <td>
                      <Link
                        style={{
                          color,
                          fontWeight: 'normal',
                        }}
                        to={links.fighter(opponent.id)}
                      >
                        { opponent.name }
                      </Link>
                    </td>
                    <td width={30}>{ lastFight.nbRounds }</td>
                    <td width={50}>{ lastFight.lastRoundDuration && Duration.fromObject({ seconds: lastFight.lastRoundDuration }).toFormat('m:ss') }</td>
                    <td>{ lastFight.method }</td>
                  </tr>
                );
              })
          }
        </tbody>
      </Table>
    </CopyToClipboard>
  ) : null;
};

export default LastFightsList;
