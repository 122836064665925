import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Helmet } from 'react-helmet';
import UsersList from 'components/users/UsersList';
import Sidebar from 'components/UI/Sidebar';
import { useGetUsersQuery } from 'services/users/api';

const UsersPage = () => {
  const { data: users } = useGetUsersQuery();

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <Sidebar />
      <Container
        fluid
        style={{
          paddingTop: 110,
        }}
      >
        <Row>
          <Col>
            <div style={{ marginLeft: 100 }}>
              <h3>Users</h3>
              <UsersList
                users={users}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default UsersPage;
