import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { Helmet } from 'react-helmet';
import ApiKeysList from 'components/admin/settings/ApiKeysList';
import PaymentsList from 'components/payments/PaymentsList';
import Sidebar from 'components/UI/Sidebar';
import { useGetPaymentsQuery } from 'services/payments/api';

const SettingsPage = () => {
  const { data: payments } = useGetPaymentsQuery();

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <Sidebar />
      <Container
        fluid
        style={{
          paddingTop: 110,
        }}
      >
        <Row>
          <Col>
            <div style={{ marginLeft: 100 }}>
              <h3>Settings</h3>
              <Tabs defaultActiveKey="PROFILE">
                <Tab
                  eventKey="PROFILE"
                  title="Profile"
                >
                  <PaymentsList
                    payments={payments}
                  />
                </Tab>
                <Tab
                  eventKey="PAYMENTS"
                  title="Payment History"
                >
                  <PaymentsList
                    payments={payments}
                  />
                </Tab>
                <Tab
                  eventKey="API_KEYS"
                  title="API Keys"
                >
                  <ApiKeysList />
                </Tab>
              </Tabs>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default SettingsPage;
