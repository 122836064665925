import React from 'react';
import Table from 'react-bootstrap/Table';
import { DateTime } from 'luxon';
import { Payment } from 'services/payments/types';

interface PaymentsListProps {
  payments?: Payment[] | null,
}

const PaymentsList = ({
  payments,
}: PaymentsListProps) => {
  return (
    <Table
      responsive
      striped
      bordered
      hover
    >
      <thead>
        <tr>
          <th>Date</th>
          <th style={{ width: 100 }}>Amount</th>
        </tr>
      </thead>
      <tbody>
        {
          payments?.map(payment => (
            <tr key={payment.id}>
              <td>{DateTime.fromISO(payment.createdAt).toFormat('yyyy-MM-dd hh:mm:ss')}</td>
              <td>{payment.amount.toFixed(2)} $</td>
            </tr>
          ))
        }
      </tbody>
    </Table>
  );
};

export default PaymentsList;
