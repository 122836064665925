import { combineReducers } from 'redux';
import { createLogger } from 'redux-logger';
import promise from 'redux-promise';
import thunk from 'redux-thunk';
import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { apiContests } from 'services/contests/api';
import { apiEvents } from 'services/events/api';
import { apiFights } from 'services/fights/api';
import { apiFighters } from 'services/fighters/api';
import { apiFiles } from 'services/files/api';
import { apiLogin } from 'services/login/api';
import authReducer from 'services/login/slice';
import { apiNews } from 'services/news/api';
import { apiPayments } from 'services/payments/api';
import { apiPosts } from 'services/posts/api';
import { apiPredictions } from './predictions/api';
import { apiRevisions } from './revisions/api';
import { apiUsers } from 'services/users/api';

let middlewares;
if (process.env.NODE_ENV === 'production') {
  middlewares = [thunk, promise];
} else {
  const logger = createLogger();
  middlewares = [thunk, promise, logger];
}

const store = configureStore({
  reducer: combineReducers({
    auth: authReducer,
    [apiContests.reducerPath]: apiContests.reducer,
    [apiEvents.reducerPath]: apiEvents.reducer,
    [apiFights.reducerPath]: apiFights.reducer,
    [apiFighters.reducerPath]: apiFighters.reducer,
    [apiFiles.reducerPath]: apiFiles.reducer,
    [apiLogin.reducerPath]: apiLogin.reducer,
    [apiNews.reducerPath]: apiNews.reducer,
    [apiPayments.reducerPath]: apiPayments.reducer,
    [apiPosts.reducerPath]: apiPosts.reducer,
    [apiPredictions.reducerPath]: apiPredictions.reducer,
    [apiRevisions.reducerPath]: apiRevisions.reducer,
    [apiUsers.reducerPath]: apiUsers.reducer,
  }),
  devTools: process.env.NODE_ENV !== 'production',
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(
      ...middlewares,
      apiContests.middleware,
      apiEvents.middleware,
      apiFights.middleware,
      apiFighters.middleware,
      apiFiles.middleware,
      apiLogin.middleware,
      apiNews.middleware,
      apiPayments.middleware,
      apiPosts.middleware,
      apiPredictions.middleware,
      apiRevisions.middleware,
      apiUsers.middleware,
    ),
});

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);

export default store;

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
