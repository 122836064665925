import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import envvars from 'utils/envvars';
import {
  EventCreatedNews,
  EventUpdatedNews,
  EventDeletedNews,
  FightCreatedNews,
  FightUpdatedNews,
  FightDeletedNews,
} from './types';

type News = 
  EventCreatedNews
  | EventUpdatedNews
  | EventDeletedNews
  | FightCreatedNews
  | FightUpdatedNews
  | FightDeletedNews;

export const apiNews = createApi({
  reducerPath: 'newsApi',
  tagTypes: ['News'],
  baseQuery: fetchBaseQuery({
    baseUrl: (process.env.NODE_ENV === 'production')
      ? `${envvars.apiServer}/v1`
      : 'http://localhost:3000/v1/',
    credentials: 'include',
  }),
  endpoints: builder => ({
    getNews: builder.query<News[], void>({
      query: () => `news`,
      transformResponse: (data: { news: News[] }) => data.news,
      transformErrorResponse: (response: { status: string | number }) => response.status,
      providesTags: ['News'],
    }),
  }),
});

export const {
  useGetNewsQuery,
} = apiNews;
