import React from 'react';
import { Link } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import { DateTime } from 'luxon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import links from 'utils/links';

const FilesList = ({
  files,
}) => {
  return (
    <Table
      bordered
      hover
      responsive
      striped
    >
      <thead>
        <tr>
          <th style={{ width: 65 }}>&nbsp;</th>
          <th>Name</th>
          <th>Author</th>
          <th style={{ width: 150 }}>Created</th>
          <th style={{ width: 100 }}>Published</th>
          <th style={{ width: 30 }}>&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        {
          [...(files || [])]
            .sort(file => file.Key)
            .reverse()
            .map(file => (
              <tr
                key={file.Key}
              >
                <td
                  style={{
                    ...(file.mainImage && {
                      backgroundImage: `url("${file.mainImage}")`,
                      backgroundSize: 'cover',
                    }),
                  }}
                />
                <td>
                  {file.Key}
                </td>
                <td>
                  <Link to={links.user(file.userName)}>
                    {file.userName}
                  </Link>
                </td>
                <td>
                  {DateTime.fromISO(file.createdAt).toRelative()}
                </td>
                <td />
                <td>
                  <FontAwesomeIcon
                    icon="times"
                    onClick={() => undefined}
                    size="lg"
                    style={{
                      color: 'red',
                      cursor: 'pointer',
                    }}
                  />
                </td>
              </tr>
            ))
        }
      </tbody>
    </Table>
  );
};

export default FilesList;
