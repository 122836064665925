import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Helmet } from 'react-helmet';
import DataList from 'components/admin/data/DataList';
import Sidebar from 'components/UI/Sidebar';
import { useLazyGetFightsQuery, useLazyGetFightsForFighterQuery } from 'services/fights/api';
import { Fight } from 'services/fights/types';
import links from 'utils/links';

const AdminDataPage = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const nbFightsPerPage = 100;
  const fighterId = searchParams.get('fighterId');
  const from = searchParams.get('from');
  const [getFights] = useLazyGetFightsQuery();
  const [getFightsForFighter] = useLazyGetFightsForFighterQuery();
  const [fights, setFights] = useState<Fight[]>([]);

  useEffect(() => {
    if (fighterId) {
      getFightsForFighter(fighterId)
        .then(({ data }) => {
          if (data) {
            setFights(data);
          }
        });
    } else {
      getFights({
        filter: {
          ...(from && ({
            date: {
              $lte: from,
            },
          })),
        },
        sort: undefined,
        limit: nbFightsPerPage,
      })
        .then(({ data }) => {
          if (data) {
            setFights(data);
          }
        });    
    }
  }, [from]);

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <Sidebar />
      <Container
        fluid
        style={{
          paddingTop: 110,
        }}
      >
        <Row>
          <Col>
            <div
              style={{
                marginLeft: 100,
              }}
            >
              <h3>Data</h3>
              <DataList
                fights={fights}
                onChangePage={date => {
                  navigate(links.adminData({ from: date }));
                }}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AdminDataPage;
