import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import RBForm from 'react-bootstrap/Form';
import { Form, Field } from 'react-final-form';
import usePrevious from 'use-previous';
import { useVerifyMutation } from 'services/login/api';
import { useAuth } from 'services/login/hooks';
import { setCredentials } from 'services/login/slice';
import links from 'utils/links';
import './style.scss';

const VerifyPage = () => {
  const auth = useAuth();
  const dispatch = useDispatch();
  const location = useLocation();
  const { search } = useLocation();
  const navigate = useNavigate();
  const urlSearchParams = new URLSearchParams(search);
  const [showLoginError] = useState(false);
  const prevAuthUser = usePrevious(auth.user);
  const emailAddress = urlSearchParams.get('emailAddress') || undefined;
  const emailVerificationCode = urlSearchParams.get('code') || undefined;

  const [verify] = useVerifyMutation();

  useEffect(() => {
    // operations.login.verify({
    //   emailAddress,
    //   emailVerificationCode,
    // })(dispatch)
    //   .catch(error => console.error(error));
  }, [emailAddress, emailVerificationCode]);

  useEffect(() => {
    if (!prevAuthUser && auth.user) {
      navigate(location.state ? location.state.from : links.paths.admin);
    }
  }, [auth.user]);

  const onSubmit = async values => {
    const user: any = await verify(values).unwrap();
    dispatch(setCredentials({ user }));
    navigate(
      location.state
        ? location.state.from
        : links.paths.admin,
    );
    // operations.signup.signup(values)(dispatch)
    //   .then(() => {
    //     history.push(location.state ? location.state.from : links.paths.admin);
    //   })
    //   .catch(() => {
    //     setShowLoginError(true);
    //   });
  };

  return (
    <div className="Verify">
      {
        showLoginError && (
          <Alert variant="danger" >
            <strong>Invalid verification</strong>
          </Alert>
        )
      }
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <RBForm.Group>
              <RBForm.Label>Email address</RBForm.Label>
              <Field
                className="form-control"
                component="input"
                defaultValue={emailAddress}
                name="emailAddress"
                type="text"
              />
            </RBForm.Group>
            <RBForm.Group>
              <RBForm.Label>Code</RBForm.Label>
              <Field
                autoFocus
                className="form-control"
                component="input"
                defaultValue={emailVerificationCode}
                name="code"
                type="text"
              />
            </RBForm.Group>
            <br />
            <div>
              <Button
                variant="primary"
                type="submit"
              >
                Sign up
              </Button>
              &nbsp;
              or
              &nbsp;
              <Link to={links.paths.login}>Log in</Link>
            </div>
          </form>
        )}
      />
    </div>
  );
};

export default VerifyPage;
