import React from 'react';
import Table from 'react-bootstrap/Table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Prediction } from 'services/predictions/types';
import { useGetContestsQuery } from 'services/contests/api';

interface PredictionsListProps {
  predictions?: Prediction[] | null,
}

const PredictionsList = ({
  predictions,
}: PredictionsListProps) => {
  const { data: contests } = useGetContestsQuery();

  return (
    <Table
      responsive
      striped
      bordered
      hover
    >
      <thead>
        <tr>
          <th style={{ width: 150 }}>Date</th>
          <th style={{ width: 350 }}>Contest</th>
          <th>Forecasts</th>
          <th style={{ width: 30 }}>&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        {
          [...(predictions || [])]
            .sort((a, b) => (a.eventDate < b.eventDate ? 1 : -1))
            .map(prediction => {
              const contest = contests?.find(c => c.id === prediction.contestId);
              return (
                <tr key={prediction.id}>
                  <td>
                    {prediction.eventDate}
                  </td>
                  <td>
                    {contest?.title}
                  </td>
                  <td>
                    {
                      prediction.forecasts
                        .map(forecast => {
                          let strForecast;
                          if (forecast.winnerId === 'Draw') {
                            strForecast = forecast.winnerName;
                          } else if (forecast.method === 'Decision') {
                            strForecast = `${forecast.winnerName} by ${forecast.method}`;
                          } else {
                            strForecast = `${forecast.winnerName} by ${forecast.method} in R${forecast.round}`;
                          }
                          return (
                            <div key={forecast.fightId}>
                              {strForecast}
                            </div>
                          );
                        })
                    }
                  </td>
                  <td>
                    <FontAwesomeIcon
                      icon="edit"
                      onClick={() => undefined}
                      size="1x"
                      style={{
                        color: 'blue',
                        cursor: 'pointer',
                      }}
                    />
                  </td>
                </tr>
              );
            })
        }
      </tbody>
    </Table>
  );
};

export default PredictionsList;
