import React, { useState } from 'react';
import { DateTime } from 'luxon';
import humanizeDuration from 'humanize-duration';
import useInterval from 'use-interval';

const ContestStatus = ({
  contest,
}) => {
  const [, setNow] = useState(DateTime.now());

  useInterval(() => {
    setNow(DateTime.now());
  }, 60000);

  const now = DateTime.now();
  const startsAt = DateTime.fromISO(contest.startsAt);
  const endsAt = DateTime.fromISO(contest.endsAt);
  const hasStarted = now > startsAt;
  const hasEnded = now > endsAt;
  const isOpen = now >= startsAt && now < endsAt;
  const startsIn = now < startsAt && (startsAt.toMillis() - now.toMillis());
  const endsIn = isOpen && (endsAt.toMillis() - now.toMillis());

  let status;
  if (isOpen) {
    status = `Contest ends in ${humanizeDuration(endsIn, { round: true, units: ['d', 'h', 'm'] })}`;
  } else if (hasEnded) {
    status = `Contest ended ${DateTime.fromISO(contest.endsAt).toRelative()}`;
  } else if (!hasStarted) {
    status = `Contest starts in ${humanizeDuration(startsIn, { round: true, units: ['d', 'h', 'm'] })}`;
  }
  return (
    <div
      style={{
        color: (isOpen && endsIn < 3 * 60 * 60 * 1000) ? '#de0000' : '#a9a9a9',
      }}
    >
      <b>{status}</b>
    </div>
  );
};

export default ContestStatus;
