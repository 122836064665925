import React, { useEffect, useState } from 'react';
import ContentEditable from 'react-contenteditable';
import usePrevious from 'use-previous';
import useTimeout from 'use-timeout';
import { SaveState } from './SaveState';
import SaveStateMarker from './SaveStateMarker';

interface AutosaveTextareaProps {
  defaultValue?: string;
  onSave: (value?: string) => Promise<void>;
  showSavedCheck?: boolean;
  style?: React.CSSProperties;
  useContentEditableDiv?: boolean;
}

const AutosaveTextarea = ({
  defaultValue,
  onSave,
  showSavedCheck,
  style,
  useContentEditableDiv,
}: AutosaveTextareaProps) => {
  const prevDefaultValue = usePrevious(defaultValue);
  const [value, setIValue] = useState(defaultValue || '');
  const [delay, setDelay] = useState<number | null>(null);
  const [saveState, setSaveState] = useState(SaveState.CLEAN);
  const [savedAt, setSavedAt] = useState<Date | null>(null);

  useEffect(() => {
    if (defaultValue || defaultValue === '') {
      setIValue(defaultValue);
    }
  }, [defaultValue]);

  const saveValue = () => {
    setSaveState(SaveState.SAVING);
    if (value !== '') {
      onSave(value)
        .then(() => {
          setSaveState(SaveState.SAVED);
          setSavedAt(new Date());
        });
    }
  };

  useTimeout(() => {
    saveValue();
    setDelay(null);
  }, delay);

  const setValue = (val, save = true) => {
    if (save) {
      setDelay(1000);
    }
    setIValue(val);
  };

  useEffect(() => {
    if (!prevDefaultValue) {
      setValue(defaultValue, false);
    }
  }, [defaultValue]);

  return (
    <div>
      {
        showSavedCheck && (
          <SaveStateMarker
            isSaving={saveState === SaveState.SAVING}
            savedAt={savedAt}
            style={{
              bottom: 160,
              float: 'right',
              position: 'absolute',
              right: 50,
            }}
          />
        )
      }
      {
        useContentEditableDiv
          ? (
            <ContentEditable
              id="text"
              html={value}
              onChange={e => setValue(e.target.value)}
              onClick={e => e.stopPropagation()}
              style={{
                border: '1px solid #d0d0d0',
                borderRadius: 3,
                cursor: 'auto',
                display: 'inline-block',
                height: 300,
                minWidth: 'auto',
                outline: 'none',
                overflowY: 'scroll',
                paddingLeft: 10,
                paddingRight: 50,
                paddingTop: 10,
                textAlign: 'left',
                whiteSpace: 'pre',
                width: '100%',
                ...style,
              }}
            />
          ) : (
            <textarea
              id="text"
              onChange={e => setValue(e.target.value)}
              onClick={e => e.stopPropagation()}
              style={{
                border: '1px solid #d0d0d0',
                borderRadius: 3,
                cursor: 'auto',
                display: 'inline-block',
                height: 300,
                minWidth: 'auto',
                outline: 'none',
                overflowY: 'scroll',
                paddingLeft: 10,
                paddingRight: 50,
                paddingTop: 10,
                textAlign: 'left',
                width: '100%',
                ...style,
              }}
              value={value}
            />
          )
      }
    </div>
  );
};

export default AutosaveTextarea;
