import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Helmet } from 'react-helmet';
import FilesList from 'components/admin/FilesList';
import Sidebar from 'components/UI/Sidebar';
import { useGetFilesQuery } from 'services/files/api';

const FilesPage = () => {
  const { data: files } = useGetFilesQuery();

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <Sidebar />
      <Container
        fluid
        style={{
          paddingTop: 110,
        }}
      >
        <Row>
          <Col>
            <div
              style={{ marginLeft: 100 }}
            >
              <h3>Files</h3>
                <FilesList
                  files={files}
                />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default FilesPage;
