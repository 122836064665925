import React from 'react';
import Table from 'react-bootstrap/Table';
import MDX from '@mdx-js/runtime';
import { invertColor } from 'utils/colors';

const PostText = ({
  text,
}) => {
  const components = {
    FightTable: ({ children, title, width }) => {
      return (
        <>
          <p style={{ textAlign: 'center' }}><b>{ title }</b></p>
          <Table
            style={{
              marginLeft: 'auto',
              marginRight: 'auto',
              width: width || undefined,
            }}
          >
            <tr style={{ backgroundColor: '#d4d4d4' }}>
              <th style={{ width: 25 }}>&nbsp;</th>
              <th style={{ width: 120 }}>Date</th>
              <th>Opponent</th>
              <th style={{ width: 50 }}>Round</th>
              <th>Time</th>
              <th>Method</th>
            </tr>
            { children }
          </Table>
        </>
      );
    },
    img: ({ alt, src, title }) => (
      <>
        <p style={{ textAlign: 'center' }}><b>{ title }</b></p>
        <div style={{ marginBottom: 10, textAlign: 'center' }}>
          <img src={src} alt={alt || title} />
        </div>
      </>
    ),
    p: props => (
      <p
        style={{ textAlign: 'justify' }}
        {...props}
      >
        { props.children }
      </p>
    ),
    Prediction: ({ children, color }) => {
      const backgroundColor = color || '#FFF6CF';
      const textColor = invertColor(backgroundColor);
      return (
        <div
          style={{
            backgroundColor,
            color: textColor,
            marginBottom: 15,
            padding: 5,
          }}
        >
          My prediction:&nbsp;
          <b>{ children }</b>
        </div>
      );
    },
    SideBySide: ({ children }) => {
      return (
        <div style={{ textAlign: 'center' }}>
          {
            children.map(child => (
              <div
                style={{
                  display: 'inline-block',
                  padding: '0 10px',
                }}
              >
                {child}
              </div>
            ))
          }
        </div>
      );
    },
    table: ({ children, title, width }) => {
      return (
        <div style={{ textAlign: 'center' }}>
          <p><b>{ title }</b></p>
          <Table
            striped
            style={{
              marginLeft: 'auto',
              marginRight: 'auto',
              width: width || undefined,
            }}
          >
            { children }
          </Table>
        </div>
      );
    },
    th: ({ children, style, width }) => (
      <th
        style={{
          ...style,
          backgroundColor: '#d4d4d4',
          width: width || undefined,
        }}
      >
        { children }
      </th>
    ),
    Video: ({ title, videoId }) => (
      <div style={{ textAlign: 'center' }}>
        <p><b>{ title }</b></p>
        <iframe
          width="560"
          height="315"
          src={`https://www.youtube.com/embed/${videoId}`}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen>
        </iframe>
      </div>
    ),
  };

  const scope = {
    some: 'value',
  };

  return (
    <MDX components={components} scope={scope}>
      {text}
    </MDX>
  );
};

export default PostText;
