import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { formatYYYYMMDD } from 'utils/date';
import envvars from 'utils/envvars';
import { Fight } from './types';

export const apiFights = createApi({
  reducerPath: 'fightsApi',
  tagTypes: ['Fight'],
  baseQuery: fetchBaseQuery({
    baseUrl: (process.env.NODE_ENV === 'production')
      ? `${envvars.apiServer}/v1`
      : 'http://localhost:3000/v1/',
    credentials: 'include',
  }),
  endpoints: builder => ({
    getFights: builder.query<Fight[], any>({
      query: ({
        filter = { date: { $gte: formatYYYYMMDD() }},
        sort,
        limit,
      }) => {
        return {
          url: 'fights',
          params: {
            filter: JSON.stringify(filter),
            sort: JSON.stringify(sort),
            limit,
          },
        };
      },
      transformResponse: (data: { fights: Fight[] }) => data.fights,
      transformErrorResponse: (response: { status: string | number }) => response.status,
      providesTags: ['Fight'],
    }),
    getFightsForFighter: builder.query<Fight[], string>({
      query: fighterId => `fighters/${fighterId}/fights`,
      transformResponse: (data: { fights: Fight[] }) => data.fights,
      transformErrorResponse: (response: { status: string | number }) => response.status,
      providesTags: ['Fight'],
    }),
    getFight: builder.query<Fight, string>({
      query: id => `fights/${id}`,
      transformResponse: (data: { fight: Fight }) => data.fight,
      transformErrorResponse: (response: { status: string | number }) => response.status,
      providesTags: ['Fight'],
    }),
    addFight: builder.mutation<Fight, Partial<Fight>>({
      query: body => ({
        url: `fights`,
        method: 'POST',
        body,
      }),
      transformResponse: (data: { fight: Fight }) => data.fight,
      transformErrorResponse: (response: { status: string | number }) => response.status,
      invalidatesTags: ['Fight'],
    }),
    patchFight: builder.mutation<Fight, Partial<Fight> & Pick<Fight, 'id'>>({
      query: ({ id, ...body }) => ({
        url: `fights/${id}`,
        method: 'PATCH',
        body,
      }),
      transformResponse: (data: { fight: Fight }) => data.fight,
      transformErrorResponse: (response: { status: string | number }) => response.status,
      invalidatesTags: ['Fight'],
    }),
    updateFight: builder.mutation<Fight, Partial<Fight> & Pick<Fight, 'id'>>({
      query: ({ id, ...body }) => ({
        url: `fights/${id}`,
        method: 'PUT',
        body,
      }),
      transformResponse: (data: { fight: Fight }) => data.fight,
      transformErrorResponse: (response: { status: string | number }) => response.status,
      invalidatesTags: ['Fight'],
    }),
    deleteFight: builder.mutation<string, string>({
      query: id => ({
        url: `fights/${id}`,
        method: 'DELETE',
      }),
      transformResponse: (data: { fightId: string }) => data.fightId,
      transformErrorResponse: (response: { status: string | number }) => response.status,
      invalidatesTags: ['Fight'],
    }),
  }),
});

export const {
  useGetFightsQuery,
  useLazyGetFightsQuery,
  useGetFightsForFighterQuery,
  useLazyGetFightsForFighterQuery,
  useLazyGetFightQuery,
  useAddFightMutation,
  usePatchFightMutation,
  useUpdateFightMutation,
  useDeleteFightMutation,
} = apiFights;
