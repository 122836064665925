import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { LinkContainer } from 'react-router-bootstrap';
import { useDetectClickOutside } from 'react-detect-click-outside';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLogoutMutation } from 'services/login/api';
import { useAuth } from 'services/login/hooks';
import { setCredentials } from 'services/login/slice';
import links from 'utils/links';
import logo from '../images/logo.png';
import './style.scss';

const TopMenu = () => {
  const auth = useAuth();
  const dispatch = useDispatch();
  const ref = useDetectClickOutside({ onTriggered: () => setDisplayDropdown(false) });
  const location = useLocation();
  const navigate = useNavigate();
  const [displayDropdown, setDisplayDropdown] = useState(false);

  const [logout] = useLogoutMutation();

  const handleLogout = () => {
    logout()
      .then(() => {
        dispatch(setCredentials({
          user: null,
        }));
        navigate(location);
      });
  };

  return (
    <div>
      <Container
        style={{
          position: 'fixed',
          minWidth: '100%',
          paddingTop: 0,
          paddingLeft: 0,
          paddingRight: 0,
          zIndex: 1000,
        }}
      >
        <Navbar
          bg="light"
          collapseOnSelect
          expand="lg"
          expanded={displayDropdown}
          onToggle={setDisplayDropdown}
          ref={ref}
        >
          <Navbar.Brand href="/">
            <img
              src={logo}
              alt="loserswinbig.com"
              className="d-inline-block align-top"
              height="70"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              <LinkContainer to={links.paths.home}><Nav.Link>Home</Nav.Link></LinkContainer>
              <LinkContainer to={links.paths.blog}><Nav.Link>Blog</Nav.Link></LinkContainer>
              <LinkContainer to={links.paths.contests}><Nav.Link>Contests</Nav.Link></LinkContainer>
            </Nav>
            <Nav className="ms-auto">
              {
                auth.user ? (
                  <NavDropdown
                    align="end"
                    style={{
                      minWidth: 150,
                      textAlign: 'right',
                    }}
                    title={auth.user.username}
                  >
                    <NavDropdown.Item href={links.paths.admin}>My Account</NavDropdown.Item>
                    <NavDropdown.Item href={links.paths.adminSettings}>Settings</NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item onClick={() => handleLogout()}>Log out</NavDropdown.Item>
                  </NavDropdown>
                ) : (
                  <LinkContainer to={links.paths.login}>
                    <Nav.Link>
                      <FontAwesomeIcon icon="sign-in" /> Sign in
                    </Nav.Link>
                  </LinkContainer>
                )
              }
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </Container>
    </div>
  );
};

export default TopMenu;
