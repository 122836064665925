import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { useMetaMask } from 'metamask-react';
import ClickOutside from 'react-click-outside';
import { Helmet } from 'react-helmet';
import SlidingPane from 'react-sliding-pane';
import Sidebar from 'components/UI/Sidebar';
import PaymentForm from 'components/admin/PaymentForm';
import PaymentsList from 'components/payments/PaymentsList';
import { useAuth } from 'services/login/hooks';
import { useGetPaymentsQuery } from 'services/payments/api';

const PaymentsPage = () => {
  const auth = useAuth();
  const { data: payments } = useGetPaymentsQuery();
  const [editedPaymentId, setEditedPaymentId] = useState<string | null>(null);

  const {
    // status,
    connect,
    // account,
    // chainId,
  } = useMetaMask();
  /*
  if (status === "initializing") console.log('Synchronization with MetaMask ongoing');
  if (status === "unavailable") console.log('Metamask not available');
  if (status === "notConnected"){} 
  if (status === "connecting") console.log('Connecting');
  if (status === "connected") console.log('Connected account', account, 'on chain ID', chainId);
  */

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <Sidebar />
      <Container
        fluid
        style={{
          paddingTop: 110,
        }}
      >
        <Row>
          <Col>
            <div style={{ marginLeft: 100 }}>
              {
                auth.user.permissions?.includes('payments.create') && (
                  <Button
                    onClick={() => setEditedPaymentId('NEW')}
                    style={{
                      float: 'right',
                    }}
                    variant="primary"
                  >
                    Add Funds
                  </Button>
                )
              }
              <h3>Payments</h3>
              <PaymentsList
                payments={payments}
              />
              <Button onClick={connect}>Connect to MetaMask</Button>
            </div>
            <SlidingPane
              className="payment_sliding-pane"
              isOpen={!!editedPaymentId}
              onRequestClose={() => setEditedPaymentId(null)}
              overlayClassName="slidingPane_overlay"
            >
              <ClickOutside
                onClickOutside={() => setEditedPaymentId(null)}
              >
                <PaymentForm
                  onClose={() => setEditedPaymentId(null)}
                  paymentId={editedPaymentId}
                />
              </ClickOutside>
            </SlidingPane>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default PaymentsPage;
