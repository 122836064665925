import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ReactGA from 'react-ga4';
import { Helmet } from 'react-helmet';
import FightsList from 'components/fights/FightsList';
import LoadingGif from 'components/UI/LoadingGif';
import { useLazyGetEventQuery } from 'services/events/api';
import { Event } from 'services/events/types';
import { useGetFightsQuery } from 'services/fights/api';

const EventPage = () => {
  const location = useLocation();
  const { id: eventId } = useParams();
  const [event, setEvent] = useState<Event | null>(null);
  const [getEvent] = useLazyGetEventQuery();
  const { data: fights } = useGetFightsQuery({
    filter: { eventId },
  });

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: location.pathname,
    });
  }, []);

  useEffect(() => {
    if (eventId) {
      getEvent(eventId)
        .then(({ data }) => {
          if (data) {
            setEvent(data);
          }
        });
    }
  }, [eventId]);

  if (!event) {
    return <LoadingGif />;
  }
  return (
    <>
      <Helmet>
        <title>LosersWinBig - { event.name }</title>
      </Helmet>
      <Container
        fluid
        style={{
          paddingTop: 110,
        }}
      >
        <Row>
          <Col xl={10} lg={12}>
            <h3>{event.name}</h3>
            <div style={{ textAlign: 'left' }}>
              <b>Date:</b> {event.date}
            </div>
            <br />
            <FightsList fights={fights} />
          </Col>
          <Col xl={2}></Col>
        </Row>
      </Container>
    </>
  );
};

export default EventPage;
