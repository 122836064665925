import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ReactGA from 'react-ga4';
import { Helmet } from 'react-helmet';
import LastFightsList from 'components/fights/LastFightsList';
import LoadingGif from 'components/UI/LoadingGif';
import { useLazyGetFighterQuery } from 'services/fighters/api';
import { Fighter } from 'services/fighters/types';

const FighterPage = () => {
  const location = useLocation();
  const { id: fighterId } = useParams();
  const [fighter, setFighter] = useState<Fighter | null>(null);
  const [getFighter] = useLazyGetFighterQuery();

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: location.pathname,
    });
  }, []);

  useEffect(() => {
    if (fighterId) {
      getFighter(fighterId)
        .then(({ data }) => {
          if (data) {
            setFighter(data);
          }
        });
    }
  }, [fighterId]);

  if (!fighter) {
    return <LoadingGif />;
  }
  return (
    <>
      <Helmet>
        <title>LosersWinBig - {`${fighter?.name}`}</title>
      </Helmet>
      <Container
        fluid
        style={{
          paddingTop: 110,
        }}
      >
        <Row>
          <Col
            xl={10}
            lg={12}
          >
            {
              fighter && (
                <>
                  <h3>
                    { fighter?.name }
                  </h3>
                  <br />
                </>
              )
            }
            <h4>Past Fights</h4>
            <LastFightsList
              fighterId={fighter.id}
              name={fighter?.name}
            />
          </Col>
          <Col xl={2}></Col>
        </Row>
      </Container>
    </>
  );
};

export default FighterPage;
