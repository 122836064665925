import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import envvars from 'utils/envvars';
import { Post } from './types';

export const apiPosts = createApi({
  reducerPath: 'postsApi',
  tagTypes: ['Post'],
  baseQuery: fetchBaseQuery({
    baseUrl: (process.env.NODE_ENV === 'production')
      ? `${envvars.apiServer}/v1`
      : 'http://localhost:3000/v1/',
    credentials: 'include',
  }),
  endpoints: builder => ({
    getPosts: builder.query<Post[], void>({
      query: () => `posts`,
      transformResponse: (data: { posts: Post[] }) => data.posts,
      transformErrorResponse: (response: { status: string | number }) => response.status,
      providesTags: ['Post'],
    }),
    getPostsByUser: builder.query<Post[], string>({
      query: username => ({
        url: 'posts',
        params: {
          filter: JSON.stringify({ username }),
        },
      }),
      transformResponse: (data: { posts: Post[] }) => data.posts,
      transformErrorResponse: (response: { status: string | number }) => response.status,
      providesTags: ['Post'],
    }),
    addPost: builder.mutation<Post, Partial<Post>>({
      query: body => ({
        url: `posts`,
        method: 'POST',
        body,
      }),
      transformResponse: (data: { post: Post }) => data.post,
      transformErrorResponse: (response: { status: string | number }) => response.status,
      invalidatesTags: ['Post'],
    }),
    patchPost: builder.mutation<Post, Partial<Post> & Pick<Post, 'id'>>({
      query: ({ id, ...body }) => ({
        url: `posts/${id}`,
        method: 'PATCH',
        body,
      }),
      transformResponse: (data: { post: Post }) => data.post,
      transformErrorResponse: (response: { status: string | number }) => response.status,
      invalidatesTags: ['Post'],
    }),
    updatePost: builder.mutation<Post, Partial<Post> & Pick<Post, 'id'>>({
      query: ({ id, ...body }) => ({
        url: `posts/${id}`,
        method: 'PUT',
        body,
      }),
      transformResponse: (data: { post: Post }) => data.post,
      transformErrorResponse: (response: { status: string | number }) => response.status,
      invalidatesTags: ['Post'],
    }),
    deletePost: builder.mutation<string, string>({
      query: id => ({
        url: `posts/${id}`,
        method: 'DELETE',
      }),
      transformResponse: (data: { postId: string }) => data.postId,
      transformErrorResponse: (response: { status: string | number }) => response.status,
      invalidatesTags: ['Post'],
    }),
  }),
});

export const {
  useGetPostsQuery,
  useLazyGetPostsByUserQuery,
  useAddPostMutation,
  usePatchPostMutation,
  useUpdatePostMutation,
  useDeletePostMutation,
} = apiPosts;
