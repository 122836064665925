import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import RBForm from 'react-bootstrap/Form';
import { Form, Field } from 'react-final-form';
import { useSignupMutation } from 'services/login/api';
import { setCredentials } from 'services/login/slice';
import links from 'utils/links';
import './style.scss';

const SignupPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  // const prevLoginState = usePrevious(loginState);

  const [showLoginError] = useState(false);

  // const [login] = useLoginMutation();
  const [signup] = useSignupMutation();

  // useEffect(() => {
  //   operations.login.login({})(dispatch)
  //     .catch(error => console.error(error));
  // }, []);

  // useEffect(() => {
  //   if (!prevLoginState && loginState) {
  //     history.push(location.state ? location.state.from : links.paths.admin);
  //   }
  // }, [loginState]);

  const onSubmit = async values => {
    const user: any = await signup(values).unwrap();
    dispatch(setCredentials({ user }));
    navigate(
      location.state
        ? location.state.from
        : links.verify(user.emailAddress),
    );
    // operations.signup.signup(values)(dispatch)
    //   .then(res => {
    //     const { emailAddress } = res.payload;
    //     history.push(location.state ? location.state.from : links.verify(emailAddress));
    //   })
    //   .catch(() => {
    //     setShowLoginError(true);
    //   });
  };

  return (
    <div className="Signup">
      {
        showLoginError && (
          <Alert variant="danger" >
            <strong>Invalid signup</strong>
          </Alert>
        )
      }
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <RBForm.Group>
              <RBForm.Label>Username</RBForm.Label>
              <Field
                autoFocus
                className="form-control"
                component="input"
                name="username"
                type="text"
              />
            </RBForm.Group>
            <RBForm.Group>
              <RBForm.Label>Email address</RBForm.Label>
              <Field
                className="form-control"
                component="input"
                name="emailAddress"
                type="text"
              />
            </RBForm.Group>
            <RBForm.Group>
              <RBForm.Label>Password</RBForm.Label>
              <Field
                className="form-control"
                component="input"
                type="password"
                name="password"
              />
            </RBForm.Group>
            <RBForm.Group>
              <RBForm.Label>Password confirmation</RBForm.Label>
              <Field
                className="form-control"
                component="input"
                type="password"
                name="passwordConfirmation"
              />
            </RBForm.Group>
            <br />
            <div>
              <Button
                variant="primary"
                type="submit"
              >
                Sign up
              </Button>
              &nbsp;
              or
              &nbsp;
              <Link to={links.paths.login}>Log in</Link>
            </div>
          </form>
        )}
      />
    </div>
  );
};

export default SignupPage;
