import React from 'react';
import Table from 'react-bootstrap/Table';
import { DateTime } from 'luxon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { User } from 'services/users/types';

interface UsersListProps {
  users?: User[] | null,
}

const UsersList = ({
  users,
}: UsersListProps) => {
  return (
    <Table
      responsive
      striped
      bordered
      hover
    >
      <thead>
        <tr>
          <th style={{ width: 350 }}>Name</th>
          <th>Email Address</th>
          <th>Phone Number</th>
          <th>Permissions</th>
          <th style={{ width: 150 }}>Created</th>
          <th style={{ width: 30 }}>&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        {
          users?.sort((a, b) => (a.username < b.username ? 1 : -1))
            .map(user => (
              <tr key={user.id}>
                <td>
                  {user.username}
                </td>
                <td>
                  {user.emailAddress}
                  &nbsp;
                  {
                    user.emailAddressVerified && (
                      <FontAwesomeIcon
                        icon="circle-check"
                        size="1x"
                        style={{
                          color: 'blue',
                        }}
                      />
                    )
                  }
                </td>
                <td>
                  {user.phoneNumber}
                  &nbsp;
                  {
                    user.phoneNumberVerified && (
                      <FontAwesomeIcon
                        icon="circle-check"
                        size="1x"
                        style={{
                          color: 'blue',
                        }}
                      />
                    )
                  }
                </td>
                <td>
                  {
                    user.permissions
                      .map(permission => (
                        <div key={permission}>
                          {permission}
                        </div>
                      ))
                  }
                </td>
                <td>
                  {DateTime.fromJSDate(user.createdAt).toISO()}
                </td>
                <td>
                  <FontAwesomeIcon
                    icon="edit"
                    onClick={() => undefined}
                    size="1x"
                    style={{
                      color: 'blue',
                      cursor: 'pointer',
                    }}
                  />
                </td>
              </tr>
            ))
        }
      </tbody>
    </Table>
  );
};

export default UsersList;
