import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateTime } from 'luxon';
import useInterval from 'use-interval';

const SaveStateMarker = ({ isSaving, savedAt, style }) => {
  const [, setNow] = useState(DateTime.now());

  useInterval(() => {
    setNow(DateTime.now());
  }, 5000);

  return (
    <div
      style={{
        ...style,
        ...(isSaving && { color: 'gray' }),
        ...(!isSaving && { color: 'green' }),
      }}
    >
      {
        isSaving ? (
          <>
            <FontAwesomeIcon
              icon="ellipsis"
            />
            Saving
          </>
        ) : (
          savedAt ? (
            <>
              <FontAwesomeIcon
                icon="check"
              />
              Saved {DateTime.fromJSDate(savedAt).toRelative()}
            </>
          ) : <div />
        )
      }
    </div>
  );
};

export default SaveStateMarker;
