import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import envvars from 'utils/envvars';
import { Fighter } from './types';

export const apiFighters = createApi({
  reducerPath: 'fightersApi',
  tagTypes: ['Fighter'],
  baseQuery: fetchBaseQuery({
    baseUrl: (process.env.NODE_ENV === 'production')
      ? `${envvars.apiServer}/v1`
      : 'http://localhost:3000/v1/',
    credentials: 'include',
  }),
  endpoints: builder => ({
    getFighters: builder.query<Fighter[], void>({
      query: () => 'fighters',
      transformResponse: (data: { fighters: Fighter[] }) => data.fighters,
      transformErrorResponse: (response: { status: string | number }) => response.status,
      providesTags: ['Fighter'],
    }),
    getFighter: builder.query<Fighter, string>({
      query: id => `fighters/${id}`,
      transformResponse: (data: { fighter: Fighter }) => data.fighter,
      transformErrorResponse: (response: { status: string | number }) => response.status,
      providesTags: ['Fighter'],
    }),
    addFighter: builder.mutation<Fighter, Partial<Fighter>>({
      query: body => ({
        url: `fighters`,
        method: 'POST',
        body,
      }),
      transformResponse: (data: { fighter: Fighter }) => data.fighter,
      transformErrorResponse: (response: { status: string | number }) => response.status,
      invalidatesTags: ['Fighter'],
    }),
    patchFighter: builder.mutation<Fighter, Partial<Fighter> & Pick<Fighter, 'id'>>({
      query: ({ id, ...body }) => ({
        url: `fighters/${id}`,
        method: 'PATCH',
        body,
      }),
      transformResponse: (data: { fighter: Fighter }) => data.fighter,
      transformErrorResponse: (response: { status: string | number }) => response.status,
      invalidatesTags: ['Fighter'],
    }),
    updateFighter: builder.mutation<Fighter, Partial<Fighter> & Pick<Fighter, 'id'>>({
      query: ({ id, ...body }) => ({
        url: `fighters/${id}`,
        method: 'PUT',
        body,
      }),
      transformResponse: (data: { fighter: Fighter }) => data.fighter,
      transformErrorResponse: (response: { status: string | number }) => response.status,
      invalidatesTags: ['Fighter'],
    }),
    deleteFighter: builder.mutation<string, string>({
      query: id => ({
        url: `fighters/${id}`,
        method: 'DELETE',
      }),
      transformResponse: (data: { fighterId: string }) => data.fighterId,
      transformErrorResponse: (response: { status: string | number }) => response.status,
      invalidatesTags: ['Fighter'],
    }),
  }),
});

export const {
  useGetFightersQuery,
  useLazyGetFighterQuery,
  useAddFighterMutation,
  usePatchFighterMutation,
  useUpdateFighterMutation,
  useDeleteFighterMutation,
} = apiFighters;
