import React from 'react';
import { Link } from 'react-router-dom';
import Pagination from 'react-bootstrap/Pagination';
import Table from 'react-bootstrap/Table';
import AutosaveTextarea from 'components/UI/AutosaveTextarea/AutosaveTextarea';
import { usePatchFightMutation } from 'services/fights/api';
import links from 'utils/links';

const DataList = ({
  fights,
  onChangePage,
}) => {
  const sortedFights = [...(fights || [])]
    .sort((fightA, fightB) => ((fightA.date > fightB.date) ? -1 : 1));

  const [patchFight] = usePatchFightMutation();

  return (
    <>
      <Table
        bordered
        hover
        responsive
        striped
      >
        <thead>
          <tr>
            <th style={{ width: 120 }}>Date</th>
            <th>Fight</th>
            <th>Red rank</th>
            <th>Blue rank</th>
            <th>Red opening odds</th>
            <th>Blue opening odds</th>
            <th>Red closing odds</th>
            <th>Blue closing odds</th>
          </tr>
        </thead>
        <tbody>
          {
            sortedFights
              .map(fight => (
                <tr key={fight.id}>
                  <td>
                    {fight.date}
                  </td>
                  <td>
                    <Link to={links.adminData({ fighterId: fight.red.id })}>
                      {fight.red.name}
                    </Link>
                    &nbsp;vs&nbsp;
                    <Link to={links.adminData({ fighterId: fight.blue.id })}>
                      {fight.blue.name}
                    </Link>
                  </td>
                  <td>
                    <AutosaveTextarea
                      defaultValue={fight.red.rank?.toString() || ''}
                      onSave={async value => {
                        patchFight({
                          id: fight.id,
                          'red.rank': value ? parseInt(value, 10) : undefined,
                        });
                      }}
                      style={{
                        border: 'none',
                        height: 'auto',
                        overflowY: 'hidden',
                        padding: 0,
                      }}
                      useContentEditableDiv
                    />
                  </td>
                  <td>
                    <AutosaveTextarea
                      defaultValue={fight.blue.rank?.toString() || ''}
                      onSave={async value => {
                        patchFight({
                          id: fight.id,
                          'blue.rank': value ? parseInt(value, 10) : undefined,
                        });
                      }}
                      style={{
                        border: 'none',
                        height: 'auto',
                        overflowY: 'hidden',
                        padding: 0,
                      }}
                      useContentEditableDiv
                    />
                  </td>
                  <td>
                    <AutosaveTextarea
                      defaultValue={fight.red.openingOdds?.toString() || ''}
                      onSave={async value => {
                        patchFight({
                          id: fight.id,
                          'red.openingOdds': value ? parseInt(value, 10) : undefined,
                        });
                      }}
                      style={{
                        border: 'none',
                        height: 'auto',
                        overflowY: 'hidden',
                        padding: 0,
                      }}
                      useContentEditableDiv
                    />
                  </td>
                  <td>
                    <AutosaveTextarea
                      defaultValue={fight.blue.openingOdds?.toString() || ''}
                      onSave={async value => {
                        patchFight({
                          id: fight.id,
                          'blue.openingOdds': value ? parseInt(value, 10) : undefined,
                        });
                      }}
                      style={{
                        border: 'none',
                        height: 'auto',
                        overflowY: 'hidden',
                        padding: 0,
                      }}
                      useContentEditableDiv
                    />
                  </td>
                  <td>
                    <AutosaveTextarea
                      defaultValue={fight.red.closingOdds?.toString() || ''}
                      onSave={async value => {
                        patchFight({
                          id: fight.id,
                          'red.closingOdds': value ? parseInt(value, 10) : undefined,
                        });
                      }}
                      style={{
                        border: 'none',
                        height: 'auto',
                        overflowY: 'hidden',
                        padding: 0,
                      }}
                      useContentEditableDiv
                    />
                  </td>
                  <td>
                    <AutosaveTextarea
                      defaultValue={fight.blue.closingOdds?.toString() || ''}
                      onSave={async value => {
                        patchFight({
                          id: fight.id,
                          'blue.closingOdds': value ? parseInt(value, 10) : undefined,
                        });
                      }}
                      style={{
                        border: 'none',
                        height: 'auto',
                        overflowY: 'hidden',
                        padding: 0,
                      }}
                      useContentEditableDiv
                    />
                  </td>
                </tr>
              ))
          }
        </tbody>
      </Table>
      <Pagination>
        <Pagination.First />
        <Pagination.Prev
          onClick={() => {
            const firstFightInPage = sortedFights[0];
            onChangePage(firstFightInPage.date);
          }}
        />
        <Pagination.Item>{1}</Pagination.Item>
        <Pagination.Ellipsis />

        <Pagination.Item>{10}</Pagination.Item>
        <Pagination.Item>{11}</Pagination.Item>
        <Pagination.Item active>{12}</Pagination.Item>
        <Pagination.Item>{13}</Pagination.Item>
        <Pagination.Item disabled>{14}</Pagination.Item>

        <Pagination.Ellipsis />
        <Pagination.Item>{20}</Pagination.Item>
        <Pagination.Next
          onClick={() => {
            const lastFightInPage = sortedFights[sortedFights.length - 1];
            onChangePage(lastFightInPage.date);
          }}
        />
        <Pagination.Last />
      </Pagination>
    </>
  );
};

export default DataList;
