import React, { useEffect} from 'react';
import { useParams, useLocation } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga4';
import Post from 'components/blog/Post';
import { useGetPostsQuery } from 'services/posts/api';
import links from 'utils/links';

const PostPage = () => {
  const location = useLocation();
  const { urlId } = useParams();
  const { data: posts } = useGetPostsQuery();
  const post = posts?.find(p => p.urlId === urlId);

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: location.pathname,
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>{ `LosersWinBig - ${post?.title}` }</title>
        <meta property="og:type" content="article" key="ogtype" />
        <meta property="og:title" content={`LosersWinBig - ${post?.title}`} key="ogtitle" />
        <meta property="og:site_name" content="LosersWinBig" key="ogsitename" />
        <meta property="og:url" content={window.location.href} key="ogurl" />
        <meta property="og:description" content={post?.title} key="ogdescription" />
        <meta property="og:image" content={post?.mainImage} key="ogimage" />
      </Helmet>
      <Container
        style={{
          paddingTop: 110,
        }}
      >
        <Row>
          <Col lg={12}>
            <Breadcrumb>
              <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
              <Breadcrumb.Item href={links.blog()}>Blog</Breadcrumb.Item>
              <Breadcrumb.Item active>{post?.title}</Breadcrumb.Item>
            </Breadcrumb>
            {
              post
                ? (
                  <Post
                    post={post}
                  />
                ) : <div />
            }
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default PostPage;
