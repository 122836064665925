import React from 'react';
import { Link } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import links from 'utils/links';

const EventsList = ({
  events,
}) => {
  return (
    <Table
      responsive
      striped
      bordered
      hover
    >
      <tbody>
        {
          [...(events || [])]
            .sort((eventA, eventB) => (eventA.date < eventB.date ? -1 : 1))
            .map((event, i) => (
              <tr key={i}>
                <td width={120}>{event.date}</td>
                <td>
                  <Link to={links.event(event.id)}>
                    {event.name}
                  </Link>
                </td>
              </tr>
            ))
        }
      </tbody>
    </Table>
  );
};

export default EventsList;
